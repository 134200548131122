import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import api, { changePassword } from '../../Base/Config';
import styles from './action-pages.module.css';

export default function ChangePassword(){
    const string = useParams().string;
    const [ newPassword, setNewPassword ] = useState('');
    const [ confirmNewPassword, setConfirmNewPassword ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const [ passwordChanged, setPasswordChanged ] = useState(false);

    const submitFunction = (e) => {
        e.preventDefault();
        setLoading(true);
        api.post(changePassword, {
            token: string,
            newPassword: newPassword,
            confirmNewPassword: confirmNewPassword
        })
        .then(res => {
            setLoading(false);
            if(res.data.code === 200){
                setPasswordChanged(true);
            }
        })
        .catch(err => {
            setLoading(false);
            console.error(err);
        })
    }

    return (<>
        <div className={styles['action-page']}>
            <div className={styles['change-password']}>
                {
                    passwordChanged
                    ?<>
                        <img src="../assets/images/check.png" alt="check"/>
                        <h5>Password changed</h5>
                        <p>Your password has been successfully changed. You can now log in with your new password. If you did not make this change, please contact support immediately. <span onClick={() => window.location.replace('/login')}>Login now.</span></p>
                    </>
                    :<>
                        <h5>Change Password</h5>
                        <p>Strong passwords include numbers, letters and punctuation marks.</p>
                        <form onSubmit={submitFunction}>
                            <label>NEW PASSWORD</label>
                            <input type="email" placeholder="Enter email" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required/>
                            <label>CONFIRM PASSWORD</label>
                            <input type="email" placeholder="Enter email" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} required/>
                            <button className="button black-button" disabled={loading}>{loading ? 'Wait ...' : 'Submit'}</button>
                        </form>
                    </>
                }
            </div>
        </div>
    </>)
}
