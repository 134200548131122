import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import tokenapi, { getPlan } from '../../Base/TokenConfig';
import Sidebar from '../../User/Components/Sidebar/Sidebar';
import Modal from '../Modal/Modal';
import styles from './user-layout.module.css';

export default function UserLayout({children}) {
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const [planDetails, setPlanDetails] = useState({
        plan: "Loading"
    });

    useEffect(() => {
        // fetchPlanDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const closeModal = () => {
        setModal(false);
        localStorage.removeItem('offer');
    }

    const goToPlans = () => {
        closeModal();
        navigate('/plans');
    }

    const fetchPlanDetails = () => {
        tokenapi.get(getPlan)
        .then(res => {
            if(res.data.code === 200) {
                setPlanDetails(res.data.result);
                closeModal();
            } else {
                setPlanDetails({
                    plan: "Basic"
                });
                if(localStorage.getItem('offer')){
                    setModal(true);
                }
            }
        })
        .catch(err => {
            console.error(err);
        })
    }

    return (<>
        {/* {modal && <Modal closeModal={() => closeModal()}>
            <div className={styles['beta-version-modal']}>
                <h2>Beta Version Notice</h2>
                <div className={styles['beta-version-content']}>
                    <p><b>Welcome to ScanOnn!</b></p>
                    <p>Please note that this website is currently in its beta version. While we are excited to offer you a preview of our QR code generator, some features may not be fully developed, and you might encounter occasional bugs or incomplete functionalities.</p>
                    <p>We highly appreciate your understanding and patience as we work to improve the platform. Your feedback is invaluable to us. If you encounter any issues or have any suggestions, please do not hesitate to contact us at: <a href="mailto:support@scanonn.com">support@scanonn.com</a></p>
                    <p>Thank you for being a part of our beta testing phase and helping us make ScanOnn better!</p>
                </div>
                <button className="btn btn-primary" onClick={() => closeModal()}>Okay</button>
            </div>
        </Modal>} */}
        {modal && <Modal closeModal={() => closeModal()}>
            <div className={styles['premium-qr-service-modal']}>
                <h2>Unlock Premium QR Code Services for <span>Free!</span></h2>
                <h3>For a limited time only!!!</h3>
                <p>Get ₹24,000 worth of QR code services absolutely <span>FREE</span> for the first month!</p>
                <div className={styles['offer']}>
                    <div>
                        <h4>Take your business to the next level with customized QR solutions without any upfront cost.</h4>
                        <h5>Don't miss out - <span>offer ends soon!!!</span></h5>
                        <div className={styles['premium-qr-buttons']}>
                            <button className="btn btn-info" onClick={() => goToPlans()}>Subscribe</button>
                            <button className="btn btn-light" onClick={() => closeModal()}>Continue with basic plan</button>
                        </div>
                    </div>
                    <div>
                        <svg width="132" height="150" viewBox="0 0 88 103" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M83.4359 86.3598H85.4359V84.3598V10.4V10.3229C85.436 9.26859 85.436 8.35908 85.3748 7.60994C85.3102 6.81925 85.1676 6.03282 84.782 5.27606C84.2067 4.14708 83.2888 3.2292 82.1599 2.65396L81.2519 4.43597L82.1599 2.65396C81.4031 2.26837 80.6167 2.12574 79.826 2.06114C79.0768 1.99993 78.1673 1.99996 77.113 2L77.0359 2H10.483L10.4059 2C9.35162 1.99996 8.4421 1.99993 7.69295 2.06114C6.90226 2.12574 6.11582 2.26837 5.35906 2.65396C4.23009 3.2292 3.31221 4.14708 2.73697 5.27606C2.35138 6.03282 2.20875 6.81925 2.14415 7.60994C2.08294 8.35909 2.08297 9.26861 2.08301 10.3229L2.08301 10.4V84.3598V86.3598H4.08301H83.4359Z" fill="#F5F3FF"/>
                            <path d="M83.4359 86.3598H85.4359V84.3598V10.4V10.3229C85.436 9.26859 85.436 8.35908 85.3748 7.60994C85.3102 6.81925 85.1676 6.03282 84.782 5.27606C84.2067 4.14708 83.2888 3.2292 82.1599 2.65396L81.2519 4.43597L82.1599 2.65396C81.4031 2.26837 80.6167 2.12574 79.826 2.06114C79.0768 1.99993 78.1673 1.99996 77.113 2L77.0359 2H10.483L10.4059 2C9.35162 1.99996 8.4421 1.99993 7.69295 2.06114C6.90226 2.12574 6.11582 2.26837 5.35906 2.65396C4.23009 3.2292 3.31221 4.14708 2.73697 5.27606C2.35138 6.03282 2.20875 6.81925 2.14415 7.60994C2.08294 8.35909 2.08297 9.26861 2.08301 10.3229L2.08301 10.4V84.3598V86.3598H4.08301H83.4359Z" fill="white"/>
                            <path d="M83.4359 86.3598H85.4359V84.3598V10.4V10.3229C85.436 9.26859 85.436 8.35908 85.3748 7.60994C85.3102 6.81925 85.1676 6.03282 84.782 5.27606C84.2067 4.14708 83.2888 3.2292 82.1599 2.65396L81.2519 4.43597L82.1599 2.65396C81.4031 2.26837 80.6167 2.12574 79.826 2.06114C79.0768 1.99993 78.1673 1.99996 77.113 2L77.0359 2H10.483L10.4059 2C9.35162 1.99996 8.4421 1.99993 7.69295 2.06114C6.90226 2.12574 6.11582 2.26837 5.35906 2.65396C4.23009 3.2292 3.31221 4.14708 2.73697 5.27606C2.35138 6.03282 2.20875 6.81925 2.14415 7.60994C2.08294 8.35909 2.08297 9.26861 2.08301 10.3229L2.08301 10.4V84.3598V86.3598H4.08301H83.4359Z" stroke="#8D68DC" strokeWidth="4"/>
                            <path d="M4.08301 85.4506H2.08301V87.4506V91.6756C2.08301 91.7014 2.08301 91.7271 2.08301 91.7527C2.08297 92.807 2.08294 93.7165 2.14415 94.4656C2.20875 95.2563 2.35138 96.0428 2.73697 96.7995C3.31221 97.9285 4.23009 98.8464 5.35906 99.4216C6.11582 99.8072 6.90226 99.9498 7.69295 100.014C8.44209 100.076 9.35162 100.076 10.4059 100.076L10.483 100.076H77.0359L77.113 100.076C78.1673 100.076 79.0768 100.076 79.826 100.014C80.6167 99.9498 81.4031 99.8072 82.1599 99.4216L81.2519 97.6396L82.1599 99.4216C83.2888 98.8464 84.2067 97.9285 84.782 96.7995C85.1676 96.0428 85.3102 95.2563 85.3748 94.4656C85.436 93.7165 85.436 92.807 85.4359 91.7527V91.6756V87.4506V85.4506H83.4359H4.08301Z" fill="#8D68DC" stroke="#8D68DC" strokeWidth="4"/>
                            <mask id="mask0_147_643" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="8" y="7" width="72" height="74">
                                <rect x="8.98242" y="7.09074" width="70.0791" height="73.6979" fill="#D9D9D9"/>
                            </mask>
                            <g mask="url(#mask0_147_643)">
                                <path d="M46.9423 71.5766V65.4351H52.7822V71.5766H46.9423ZM41.1024 65.4351V50.0814H46.9423V65.4351H41.1024ZM64.4621 56.2229V43.9399H70.302V56.2229H64.4621ZM58.6222 43.9399V37.7984H64.4621V43.9399H58.6222ZM23.5826 50.0814V43.9399H29.4225V50.0814H23.5826ZM17.7427 43.9399V37.7984H23.5826V43.9399H17.7427ZM44.0223 22.4447V16.3032H49.8623V22.4447H44.0223ZM22.1226 30.1215H30.8825V20.9093H22.1226V30.1215ZM17.7427 34.7276V16.3032H35.2625V34.7276H17.7427ZM22.1226 66.9705H30.8825V57.7582H22.1226V66.9705ZM17.7427 71.5766V53.1521H35.2625V71.5766H17.7427ZM57.1622 30.1215H65.9221V20.9093H57.1622V30.1215ZM52.7822 34.7276V16.3032H70.302V34.7276H52.7822ZM58.6222 71.5766V62.3644H52.7822V56.2229H64.4621V65.4351H70.302V71.5766H58.6222ZM46.9423 50.0814V43.9399H58.6222V50.0814H46.9423ZM35.2625 50.0814V43.9399H29.4225V37.7984H46.9423V43.9399H41.1024V50.0814H35.2625ZM38.1824 34.7276V22.4447H44.0223V28.5861H49.8623V34.7276H38.1824ZM24.3126 27.8185V23.2123H28.6925V27.8185H24.3126ZM24.3126 64.6674V60.0613H28.6925V64.6674H24.3126ZM59.3521 27.8185V23.2123H63.7321V27.8185H59.3521Z" fill="#8D68DC"/>
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
        </Modal>}
        <div className={styles['dashboard']}>
            <Sidebar planDetails={planDetails} />
            <div className={styles['dashboard-content']}>
                <main>{children}</main>
            </div>
        </div>
    </>)
}
