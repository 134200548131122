import React, { useEffect, useState } from 'react';
import styles from '../../create-qr-code.module.css';

export default function UPI(props) {
    const [title, setTitle] = useState('');
    const [upi, setUpi] = useState('');
    const [name, setName] = useState('');
    const [amount, setAmount] = useState('');

    useEffect(() => {
        if(sessionStorage.getItem('qr-content')) {
            const qrContent = JSON.parse(sessionStorage.getItem('qr-content'));
            setTitle(qrContent.title);
            setUpi(qrContent.upi);
            setName(qrContent.name);
            setAmount(qrContent.amount);
        }
    }, [])

    const createFunction = (e) => {
        e.preventDefault();
        sessionStorage.setItem('qr-content', JSON.stringify({ title, upi, name, amount }));
        props.stepChangeFunction(3);
    }

    return (<>
        <form onSubmit={createFunction}>
            <div className={styles['qr-title']}>
                <label>Title*</label>
                <input type="text" placeholder="Enter QR title here" value={title} onChange={e => setTitle(e.target.value)} required/>
            </div>
            <div className={styles['section-heading']}>
                <hr/>
                <h3>Details</h3>
            </div>
            <div className={styles['create-qr-fields']}>
                <div>
                    <label>UPI ID*</label>
                    <input type="text" placeholder="Enter upi id" value={upi} onChange={e => setUpi(e.target.value)} required/>
                </div>
                <div>
                    <label>Name*</label>
                    <input type="text" placeholder="Enter name" value={name} onChange={e => setName(e.target.value)} required/>
                </div>
                <div>
                    <label>Amount*</label>
                    <input type="number" placeholder="Enter amount" value={amount} onChange={e => setAmount(e.target.value)} required/>
                </div>
            </div>
            <button type="submit" className="btn btn-primary">Next</button>
        </form>
    </>)
}