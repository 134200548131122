import React, { useState } from 'react';
import tokenapi, { qrMedia } from '../../../Base/TokenConfig';
import { downloadQr } from '../../../Utils/downloadQr';
import Message from '../../../Layouts/Message/Message';
import { uploadImage } from '../../../Utils/uploadImage';
import styles from '../my-qr-codes.module.css';

export default function MediaDetails(props){
    const singleDetail = props.singleDetail;
    const [title, setTitle] = useState(props.singleDetail.title);
    const [mediaUrl, setMediaUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');

    const allowedFormats = ['mp3', 'aac', 'wav', 'mp4', 'mov', 'wmv', 'avi', 'mkv', 'webm', 'jpg', 'jpeg', 'png'];

    const uploadFunction = async (file) => {
        const format = file.name.split('.').reverse()[0];
        if(allowedFormats.indexOf(format) === -1) {
            setMessageType('error');
            setMessage('Please choose a valid file format');
        } else {
            let image = await uploadImage(file);
            if(image.status === 'success') {
                setMessageType('success');
                setMessage(image.message);
                setMediaUrl(image.result);
            } else {
                setMessageType('error');
                setMessage(image.message);
            }
        }
    }

    const updateFunction = (e) => {
        e.preventDefault();
        setLoading(true);
        tokenapi.post(qrMedia, {
            qrType: props.singleDetail.qrType,
            categoryId: props.singleDetail.categoryId,
            requestId: props.singleDetail.requestId,
            title: title,
            mediaUrl: mediaUrl,
            s3Url: props.singleDetail.s3Url,
            encWebUrl: props.singleDetail.encWebUrl
        })
        .then(res => {
            setLoading(false);
            if(res.data.code === 200){
                props.fetchFunction();
                props.closeFunction();
                setMessageType('success');
            } else {
                setMessageType('error');
            }
            setMessage(res.data.message);
        })
        .catch(err => {
            setLoading(false);
            console.error(err);
        })
    }

    return (<>
        {message && <Message type={messageType} message={message} closeMessage={() => setMessage('')} />}
        <div className={styles["vcard-static-content"]}>
            <div>
                <img src={singleDetail.s3Url} alt="qr" />
            </div>
            <div>
                <p><span>Category:</span> {singleDetail.categoryName}</p>
                <p><span>Created on:</span> {`${new Date(singleDetail.createdAt)}`.substr(0, 24)}</p>
                <button className="btn btn-primary" onClick={() => downloadQr(singleDetail.s3Url)}><span className="fa fa-download"></span> Download QR</button>
            </div>
        </div>
        <form onSubmit={updateFunction}>
            <div className={styles["vcard-editable-content"]}>
                <p className={styles["modal-subheading"]}>Edit details</p>
                <label>Title</label>
                <input type="text" placeholder="Enter here" value={title} onChange={(e) => setTitle(e.target.value)} required/>
                <label>Media</label>
                {
                    mediaUrl
                    ?<input type="text" value="Image uploaded" disabled/>
                    :<input type="file" onChange={e => uploadFunction(e.target.files[0])} />
                }
                <button className="btn btn-primary mt-3" disabled={loading}>{loading ? 'Wait ...' : 'Submit'}</button>
            </div>
        </form>
    </>)
}