import React, { useState, useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from 'react-helmet';
import api, { contactUsQuery } from '../../Base/Config';
import Message from '../../Layouts/Message/Message';
import styles from './contact-us.module.css';

export default function ContactUs() {
    const captchaRef = useRef(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [organization, setOrganization] = useState('');
    const [service, setService] = useState('');
    const [comment, setComment] = useState('');
    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        const initMap = () => {
            const map = new window.google.maps.Map(document.getElementById("map"), {
                center: { lat: 38.9637, lng: 35.2433 }, // Center of Turkey
                zoom: 4
            });

            const locations = [
                { lat: 49.4538, lng: 11.0773, title: "Lothringer Straße 12, Nuremberg" }, // Nuremberg
                { lat: 28.4595, lng: 77.0266, title: "Plot No. 97, Gurugram" }, // Gurugram (approximate coordinates)
            ];

            locations.forEach(location => {
                new window.google.maps.Marker({
                    position: { lat: location.lat, lng: location.lng },
                    map: map,
                    title: location.title,
                });
            });
        };

        // Load the Google Maps script
        const loadScript = (src) => {
            return new Promise((resolve) => {
                const script = document.createElement('script');
                script.src = src;
                script.onload = () => resolve();
                document.body.appendChild(script);
            });
        };

        // Load the Google Maps API
        loadScript(`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_PLACES}`)
            .then(() => {
                initMap();
            });
    }, []);
  
    const validateFunction = (e) => {
        e.preventDefault();
        const token = captchaRef.current.getValue();
        if(token){
            submitFunction(token);
        } else {
            setMessageType('error');
            setMessage('Please fill the captcha first');
            setTimeout(() => {
                setMessage('');
            }, 5000);
        }
    }

    const submitFunction = (token) => {
        setLoading(true);
        api.post(contactUsQuery, {
            name: name,
            email: email,
            phone: phone,
            organization: organization,
            service: service,
            comment: comment,
            recaptchaToken: token
        })
        .then(res => {
            setLoading(false);
            if(res.data.code === 200) {
                setMessageType('success');
                emptyValues();
            } else {
                setMessageType('error');
            }
            setMessage(res.data.message);
        })
        .catch(err => {
            setLoading(false);
            console.error(err);
        })
    }

    const emptyValues = () => {
        setName('');
        setEmail('');
        setPhone('');
        setOrganization('');
        setService('');
        setComment('');
    }

    return (<>
        {message && <Message type={messageType} message={message} closeMessage={() => setMessage('')} />}
        <Helmet>
            <title>Contact Us - Scanonn</title>
            <meta name="description" content="Contact us for any inquiries or feedback Related to Qr Code. Our team is ready to assist you and ensure your experience with us is exceptional and efficient." />
        </Helmet>
        <section id={styles['banner']}>
            <div className="container">
                <div className={styles["banner"]}>
                    <div>
                        <h1>“Unlock the Power of Visual Communication, Contact Us Today!”</h1>
                    </div>
                    <div>
                        <img src="../assets/images/contact-banner.png" alt="banner" width="100%" />
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className='container'>
                <div id="map" className={styles['google-map']}></div>
                <div className={styles['address-container']}>
                    <h2 className={styles['address-heading']}>Please fill free to reach us out!</h2>
                    <div className={styles['address-box']}>
                        <p className={styles['address']}>
                            <span>Germany</span>
                            Lothringer Straße 12, Nuremberg, 90461, Bayren Germany
                            {/* +49 17669596643 */}
                        </p>
                        <p className={styles['address']}>
                            <span>India</span>
                            Plot No. 97, Prem Puri, Sector 32, Gurugram, Haryana 122001
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className='container'>
                <h2 className={styles['heading']}>Let’s Start a Conversation</h2>
                <div className={styles['contact-us']}>
                    <div>
                        <img src="../assets/images/contact.png" alt="contact" width="100%" />
                        <p>We value your feedback, inquiries, and collaboration opportunities. Whether you have a question, suggestion, or simply want to reach out, this is the place to connect with us. Our dedicated team is here to assist you and ensure that your experience with us is smooth and satisfactory.</p>
                    </div>
                    <div>
                        <form onSubmit={validateFunction}>
                            <input type="text" placeholder="Full name" value={name} onChange={(e) => setName(e.target.value)} required/>
                            <input type="text" placeholder="Email email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                            <input type="text" placeholder="Mobile number" value={phone} onChange={(e) => setPhone(e.target.value)} required/>
                            <input type="text" placeholder="Organisation"  value={organization} onChange={(e) => setOrganization(e.target.value)} required/>
                            <input type="text" placeholder="Select service" value={service} onChange={(e) => setService(e.target.value)} required/>
                            <textarea className="form-control" placeholder="Comment" value={comment} onChange={(e) => setComment(e.target.value)} required></textarea>
                            <br/>
                            <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY} ref={captchaRef} />
                            <button type="submit" className="btn btn-primary mt-3" disabled={loading}>{loading ? 'Wait ...' : 'Submit'}</button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </>)
}