import React, { useEffect, useState } from 'react';
import Message from '../../../../Layouts/Message/Message';
import { uploadImage } from '../../../../Utils/uploadImage';
import styles from '../../create-qr-code.module.css';

export default function Media(props) {
    const [title, setTitle] = useState('');
    const [mediaUrl, setMediaUrl] = useState('');
    const [messageType, setMessageType] = useState('');
    const [message, setMessage] = useState('');

    const allowedFormats = ['mp3', 'aac', 'wav', 'mp4', 'mov', 'wmv', 'avi', 'mkv', 'webm', 'jpg', 'jpeg', 'png'];

    useEffect(() => {
        if(sessionStorage.getItem('qr-content')) {
            const qrContent = JSON.parse(sessionStorage.getItem('qr-content'));
            setTitle(qrContent.title);
            setMediaUrl(qrContent.mediaUrl);
        }
    }, [])

    const createFunction = (e) => {
        e.preventDefault();
        sessionStorage.setItem('qr-content', JSON.stringify({ title, mediaUrl }));
        props.stepChangeFunction(3);
    }

    const uploadFunction = async (file) => {
        const format = file.name.split('.').reverse()[0];
        if(allowedFormats.indexOf(format) === -1) {
            setMessageType('error');
            setMessage('Please choose a valid file format');
        } else {
            let image = await uploadImage(file);
            if(image.status === 'success') {
                setMessageType('success');
                setMessage(image.message);
                setMediaUrl(image.result);
            } else {
                setMessageType('error');
                setMessage(image.message);
            }
        }
    }

    return (<>
        {message && <Message type={messageType} message={message} closeMessage={() => setMessage('')} />}
        <form onSubmit={createFunction}>
            <div className={styles['qr-title']}>
                <label>Title*</label>
                <input type="text" placeholder="Enter QR title here" value={title} onChange={e => setTitle(e.target.value)} required/>
            </div>
            <div className={styles['section-heading']}>
                <hr/>
                <h3>Details</h3>
            </div>
            <div className={styles['create-qr-fields']}>
                <div>
                    <label>Media*</label>
                    {
                        mediaUrl
                        ?<input type="text" value="Image uploaded" disabled/>
                        :<input type="file" accept={allowedFormats} onChange={e => uploadFunction(e.target.files[0])} />
                    }
                </div>
            </div>
            <button type="submit" className="btn btn-primary">Next</button>
        </form>
    </>)
}