import React, { useState, useEffect } from 'react';
import styles from './navbar.module.css';
import { Link, NavLink } from 'react-router-dom';

export default function Navbar(){
    const mobileScreenWidth = 800;
    const [ isMobView, setIsMobView ] = useState(window.innerWidth<mobileScreenWidth);
    const [ mobileMenu, setMobileMenu ] = useState(false);

    useEffect(() => {
        const setHook = () => {
            const handleResize = () => {
                if(window.innerWidth<=mobileScreenWidth&&!isMobView)
                setIsMobView(true);
                else if(window.innerWidth>mobileScreenWidth&&isMobView)
                setIsMobView(false);
            }
            window.addEventListener("resize",handleResize);

            return () => {
                window.removeEventListener("resize",handleResize);
            }
        }
        setHook(setIsMobView);
    }, [isMobView])

    function Logo(){
        return (<>
            <div>
                <Link to="/" onClick={() => setMobileMenu(false)}><img src="../assets/images/logo.png" alt="logo" /></Link>
            </div>
        </>)
    }
    
    function Menu(){
        return (<>
            <div className={styles['menu']} id="menu">
                {isMobView && <span><NavLink to="/" onClick={() => setMobileMenu(false)}>Home</NavLink></span>}
                <span><NavLink to="/about" onClick={() => setMobileMenu(false)}>About</NavLink></span>
                <span><NavLink to="/product" onClick={() => setMobileMenu(false)}>Product</NavLink></span>
                <span><NavLink to="/blog" onClick={() => setMobileMenu(false)}>Blog</NavLink></span>
                <span><NavLink to="/contact-us" onClick={() => setMobileMenu(false)}>Contact us</NavLink></span>
                <span><NavLink className={styles['login']} to="/login">Login</NavLink></span>
            </div>
        </>)
    }

    return (<>
        <section id={styles['navigation']}>
            <div className="container">
                {
                    mobileMenu
                    ?<div className={styles['mobile-navigation']}>
                        <div className={styles['mobile-navigation-logo']}>
                            <Logo />
                            <div className={styles['times-bars']} onClick={() => setMobileMenu(false)}>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <Menu />
                    </div>
                    :<div className={styles['navigation']}>
                        <Logo />
                        {
                            isMobView
                            ?<div className={styles['menu-bars']} onClick={() => setMobileMenu(true)}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            :<Menu />
                        }
                    </div>
                }
            </div>
        </section>
    </>)
}