import React from 'react';
import { Link } from 'react-router-dom';
import styles from './create-manage-qr.module.css';

export default function CreateManageQR(){
    return (<>
        <section id={styles['create-manage']}>
            <div className="container">
                <div className={styles['create-manage']}>
                    <div>
                        <h2>The Best QR Code Maker</h2>
                        <h6>Let Create, Manage and Track all High resolution QR codes. Our QR generator provides the complete analytics about QR Code.</h6>
                        <Link to="/login"><button className="btn btn-light">Generate QR Code</button></Link>
                    </div>
                    <div>
                        <img src="../assets/images/generate-qr-code.png" alt="qr-code" width="100%" />
                    </div>
                </div>
            </div>
        </section>
    </>)
}
