import axios from 'axios';
import { getToken } from '../Utils/Common';
export const baseurl = process.env.REACT_APP_BASEURL;

const tokenapi = axios.create({
    baseURL: baseurl,
    headers: {
        'versionnumber': process.env.REACT_APP_VERSION
    },
});

tokenapi.interceptors.request.use(
    (config) => {
        const token = getToken();
        if(token) {
            config.headers.token = token;
        }
        return config;
    },
    (error) => {
        console.error("Error while fetching token.")
        return Promise.reject(error);
    }
);

// Response interceptor
tokenapi.interceptors.response.use(
    (response) => {
        if (response.data.code === 401) {
            // Redirect to logout page
            window.location.href = '/logout'; // Change this to your logout route
        }
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            // Redirect to logout page
            window.location.href = '/logout'; // Change this to your logout route
        }
        return Promise.reject(error);
    }
);

export default tokenapi;

// Create QR code for website
export const createWebsiteQR = '/qrCode/url';

// Create QR code for v-card
export const createVcardQR = '/qrCode/vCardGen';

// Get QR url
export const getQrUrl = '/user/getQrUrl';

// Get list of all QR codes created with pagenumber in params
export const getQrList = '/user/getQrList/?pageNumber=';

// Get user by id
export const getUserById = '/getUserById';

// Reset password
export const resetPassword = '/user/resetPassword';

// Set password
export const setPassWord = '/user/resetPasswordGoogle';

// Fetch list of categories
export const getCategories = '/user/getCategories';

// Fetch visitors details
export const getVisitors = '/user/getVisitors/';

// Edit details of QR generated for url
export const updateWebQr = '/user/updateWebQr';

// Edit details of QR generated for Vcard
export const updateVCard = '/user/updateVCard';

// Uplaod image to s3Bucket (Works with both user and admin token)
export const uploadImg = '/user/uploadImg';

// Fetch count qr by date
export const getCountQrByDate = '/user/getCountQrByDate/';

// View details of QR analytics
export const qrAnalytics = '/user/analytics/';

// Generate image of QR generated
export const generateImage = '/user/generateImage';

// Save QR code of URL
export const createUrlQr = '/user/createUrlQr';

// Edit details of QR generated for url
export const updateUrlQr = '/user/updateUrlQr';

// Edit details of QR generated for url
export const createWhatsAppQr = '/user/createWhatsAppQr';

// Edit details of QR generated for url
export const updateWhatsAppQr = '/user/updateWhatsAppQr';

// Create QR code for visiting card
export const createVisitingCardQR = '/user/vCard';

// Create QR code for text
export const qrText = '/user/qrText';

// Create QR code for media
export const qrMedia = '/user/qrMedia';

// Request Callback
export const requestCallBack = '/user/requestCallBack';

// 
export const totalScannedQrPerUser = '/user/totalScannedQrPerUser';

// 
export const totalQrCount = '/user/totalQrCount';

// Get plan details
export const getPlan = '/user/getPlan';

// Change plan details
export const changePlan = '/user/activatePlan';

// Upload PDF file for PDF category
export const uploadFile = '/user/uploadFile';

// Create QR code for PDF category
export const qrPdf = '/user/qrPdf';

// Create QR code for Google Maps
export const createGoogleMapsQr = '/user/createGoogleMapsQr';

// Create QR code for UPI ID
export const createUpiQr = '/user/qrUpi';

// Update details for Google Maps
export const updateGoogleMaps = '/user/updateGoogleMaps';

// Update phone number of an user
export const updatePhone = '/user/updatePhone';

// Update phone number of an user
export const getUserPlan = '/user/get-plan';

// Update phone number of an user
export const upgradePlan = '/user/upgrade-plan';

// Fetch list of all services by plan
export const fetchAllServices = '/user/fetch-user-services/';
