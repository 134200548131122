import React, { useEffect, useState } from 'react';
import tokenapi, { getCategories } from '../../../Base/TokenConfig';
import { removeCategory, setCategory } from '../../../Utils/Common';
import styles from '../create-qr-code.module.css';
import Modal from '../../../Layouts/Modal/Modal';

export default function ChooseCategory(props){
    const [categories, setCategories] = useState([]);
    const [loader, setLoader] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [qrTypeModal, setQrTypeModal] = useState(false);
    const [qrType, setQrType] = useState('static');

    useEffect(() => {
        if(sessionStorage.getItem('removeDetails')){
            props.stepChangeFunction(2);
            sessionStorage.removeItem('removeDetails');
        } else {
            sessionStorage.removeItem('qr-content');
            removeCategory();
            fetchCategories();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchCategories = () => {
        tokenapi.get(getCategories)
        .then(res => {
            setLoader(false);
            if(res.data.code === 200){
                setCategories(res.data.result);
            }
        })
        .catch(err => {
            setLoader(false);
            console.error(err);
        })
    }

    const selectCategory = (x) => {
        x.qrType = qrType;
        setCategory(JSON.stringify(x));
        props.stepChangeFunction(2);
    }

    return (<>
        {qrTypeModal && <Modal closeModal={() => setQrTypeModal(false)}>
            <div className={styles['select-qr-type']}>
                <div className={styles['qr-type-heading']}>Choose QR type <img src="./assets/images/times.png" alt="times" onClick={() => setQrTypeModal(false)} /></div>
                <div className={`${styles['qr-type']} ${qrType === 'static' && styles['selected']}`} onClick={() => setQrType('static')}>
                    <div>
                        <input type="radio" name="type" checked={qrType === 'static'}/>
                    </div>
                    <div>
                        <p>Static</p>
                        <span>A static QR code contains fixed data that cannot be changed once generated, such as a URL or text, and is best suited for simple, permanent information.</span>
                    </div>
                </div>
                <div className={`${styles['qr-type']} ${qrType === 'dynamic' && styles['selected']}`} onClick={() => setQrType('dynamic')}>
                    <div>
                        <input type="radio" name="type" checked={qrType === 'dynamic'}/>
                    </div>
                    <div>
                        <p>Dynamic</p>
                        <span>A dynamic QR code allows the data or destination (like a URL) to be updated after generation, offering flexibility and the ability to track scans or change the content without needing a new code.</span>
                    </div>
                </div>
                <div className="text-center mt-3">
                    <button className="btn btn-primary" onClick={() => selectCategory(selectedCategory)}>Next <span className="fa fa-angle-double-right"></span></button>
                </div>
            </div>
        </Modal>}
        {
            loader
            ?
                <div className={styles['qr-categories']}>
                    <div className={`${styles['qr-category']} ${styles['qr-category-loader']}`}></div>
                    <div className={`${styles['qr-category']} ${styles['qr-category-loader']}`}></div>
                </div>
            :<>
                <div className={styles['qr-categories']}>
                    {categories.map((item, i) => (
                        <div className={styles[item.isActive ? 'qr-category' : 'disabled-qr-category']} onClick={() => {setSelectedCategory(item); setQrTypeModal(true);}} key={i}>
                            <div className={styles[item.isActive ? 'qr-category-image' : 'disabled-qr-category-image']}>
                                <img src={item.categoryIcon} alt="category-icon" />
                            </div>
                            <div className={styles['qr-category-info']}>
                                <p>{item.categoryName}</p>
                                <span>{item.categoryDescription}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </>
        }
    </>)
}
