import React, { useState, useEffect } from 'react';
import tokenapi, { totalScannedQrPerUser, totalQrCount } from '../../../Base/TokenConfig';
import styles from '../my-qr-codes.module.css';

export default function Analytics(){
    // const [loader, setLoader] = useState(true);
    const [qrCount, setQrCount] = useState(0);
    const [totalScans, setTotalScans] = useState(0);
    const [uniqueViews, setUniqueViews] = useState(0);
    const [totalStatic, setTotalStatic] = useState(0);

    useEffect(() => {
        fetchFunction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchFunction = () => {
        tokenapi.get(totalScannedQrPerUser)
        .then(res => {
            // setLoader(false);
            if(res.data.code === 200) {
                setTotalScans(res.data.result.totalScans);
                setUniqueViews(res.data.result.uniqueViews);
                setTotalStatic(res.data.result.totalStatic);
                fetchCountFunction();
            }
        })
        .catch(err => {
            // setLoader(false);
            console.error(err);
        })
    }

    const fetchCountFunction = () => {
        tokenapi.get(totalQrCount)
        .then(res => {
            // setLoader(false);
            if(res.data.code === 200) {
                setQrCount(res.data.result);
            }
        })
        .catch(err => {
            // setLoader(false);
            console.error(err);
        })
    }

    return (<>
        <div className={styles['analytics-cards']}>
            <div className={styles['analytics-card']} style={{backgroundColor: '#E6EDFF'}}>
                <p>Total QR Codes</p>
                <span>{qrCount}</span>
            </div>
            <div className={styles['analytics-card']} style={{backgroundColor: '#fffdeb'}}>
                <p>Total Scans</p>
                <span>{totalScans}</span>
            </div>
            <div className={styles['analytics-card']} style={{backgroundColor: '#f6eaff'}}>
                <p>Total Unique Views</p>
                <span>{uniqueViews}</span>
            </div>
            <div className={styles['analytics-card']} style={{backgroundColor: '#eafffb'}}>
                <p>Total Static QR</p>
                <span>{totalStatic}</span>
            </div>
        </div>
        <hr/>
    </>)
}
