import React, { useState, useEffect } from 'react';
import tokenapi, { getUserById, updatePhone } from '../../Base/TokenConfig';
import ResetPassword from './Components/ResetPassword';
import Navbar from '../Components/Navbar/Navbar';
import Modal from '../../Layouts/Modal/Modal';
import countryCodes from '../../Common/countryCodes.json';
import Plan from './Components/Plan';
import styles from './profile.module.css';
import Message from '../../Layouts/Message/Message';

export default function Profile(){
    const [profileDetails, setProfileDetails] = useState('');
    const [loader, setLoader] = useState(true);
    const [editModal, setEditModal] = useState(false);
    const [countryCode, setCountryCode] = useState('+91');
    const [phone, setPhone] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');

    useEffect(() => {
        fetchFunction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchFunction = () => {
        tokenapi.get(getUserById)
        .then(res => {
            setLoader(false);
            if(res.data.code === 200){
                setProfileDetails(res.data.result);
            }
        })
        .catch(err => {
            setLoader(false);
            console.error(err);
        })
    }

    const updateFunction = (e) => {
        e.preventDefault();
        setLoading(true);
        tokenapi.post(updatePhone, {
            countryCode: countryCode,
            phone: phone
        })
        .then(res => {
            setLoading(false);
            setMessage(res.data.message);
            if(res.data.code === 200){
                setMessageType('success');
                closeModal();
                fetchFunction();
            } else if(res.data.code === 401) {
                setMessageType('error');
            }
        })
        .catch(err => {
            setLoading(false);
            console.error(err);
        })
    }

    const closeModal = () => {
        setEditModal(false);
        setCountryCode('');
        setPhone('');
    }

    return (<>
        {message && <Message type={messageType} message={message} closeMessage={() => setMessage('')} />}
        {editModal && <Modal closeModal={() => closeModal()}>
            <div className={styles['edit-modal']}>
                <div className={styles['modal-heading']}>Add phone <img src="../assets/images/times.png" onClick={() => closeModal()} alt="times" /></div>
                <form onSubmit={updateFunction}>
                    <label>Phone number</label>
                    <div className='phone-input'>
                        <select value={countryCode} onChange={(e) => setCountryCode(e.target.value)} required>
                            {countryCodes.map((item, i) => (
                                <option value={item.dial_code} key={i}>{item.dial_code}</option>
                            ))}
                        </select>
                        <input type="text" placeholder="Enter phone number" value={phone} onChange={(e) => setPhone(e.target.value)} required/>
                    </div>
                    <button type="submit" className="btn btn-primary" disabled={loading}>{loading ? 'Wait ...' : 'Submit'}</button>
                </form>
            </div>
        </Modal>}
        <Navbar heading='Profile' subheading='Account Info & Password Management' />
        <div className="dashboard-content">
            {
                loader
                ?<>
                    <div className={styles['loader-box']} style={{marginBottom:'5px',borderRadius:'0',height:'36.8px',width:'250px'}}></div>
                    <div className={styles['loader-box']} style={{height:'87px'}}></div>
                    <div className={styles['loader-box']} style={{marginBottom:'5px',borderRadius:'0',height:'36.8px',width:'250px'}}></div>
                    <div className={styles['loader-box']} style={{height:'171px'}}></div>
                    <div className={styles['loader-box']} style={{marginBottom:'5px',borderRadius:'0',height:'36.8px',width:'250px'}}></div>
                    <div className={styles['loader-box']} style={{height:'147px'}}></div>
                </>
                :<>
                    <h2 className={styles['profile-card-heading']}>Personal information <span className="fa fa-edit" onClick={() => setEditModal(true)}></span></h2>
                    <div className={styles['profile-card']}>
                        <div className={styles['profile-card-details']}>
                            {
                                profileDetails.phone
                                ?<>
                                    <p>
                                        <span>Full name</span>
                                        {profileDetails.firstName} {profileDetails.lastName}
                                    </p>
                                    <p>
                                        <span>Phone</span>
                                        {profileDetails.countryCode} {profileDetails.phone}
                                    </p>
                                </>
                                :<>
                                    <p>
                                        <span>First name</span>
                                        {profileDetails.firstName}
                                    </p>
                                    <p>
                                        <span>Last name</span>
                                        {profileDetails.lastName}
                                    </p>
                                </>
                            }
                            <p>
                                <span>Email</span>
                                {profileDetails.email}
                            </p>
                            {/* <p>
                                <span>Account created on</span>
                                {`${new Date(profileDetails.createdAt)}`.substr(0, 24)}
                            </p> */}
                        </div>
                    </div>
                    <ResetPassword password={profileDetails.password} fetchFunction={fetchFunction} />
                    <Plan />
                </>
            }
        </div>
    </>)
}
