import React, { useState } from 'react';
import tokenapi, { createVisitingCardQR, uploadImg } from '../../../Base/TokenConfig';
import { addHttps } from '../../../Utils/addHttps';
import { downloadQr } from '../../../Utils/downloadQr';
import Message from '../../../Layouts/Message/Message';
import styles from '../my-qr-codes.module.css';

export default function VcardDetails(props){
    const singleDetail = props.singleDetail;
    const [title, setTitle] = useState(props.singleDetail.title);
    const [name, setName] = useState(props.singleDetail.name);
    const [about, setAbout] = useState(props.singleDetail.about);
    const [organization, setOrganization] = useState(props.singleDetail.organization);
    const [designation, setDesignation] = useState(props.singleDetail.designation);
    const [phoneWork, setPhoneWork] = useState(props.singleDetail.phoneWork);
    const [phonePrivate, setPhonePrivate] = useState(props.singleDetail.phonePrivate);
    const [landline, setLandline] = useState(props.singleDetail.landline);
    const [fax, setFax] = useState(props.singleDetail.fax);
    const [email, setEmail] = useState(props.singleDetail.email);
    const [website, setWebsite] = useState(props.singleDetail.website);
    const [address, setAddress] = useState(props.singleDetail.address);
    const [googleMap, setGoogleMap] = useState(props.singleDetail.googleMap);
    const [facebook, setFacebook] = useState(props.singleDetail.socialMedia?.facebook);
    const [twitter, setTwitter] = useState(props.singleDetail.socialMedia?.twitter);
    const [linkedin, setLinkedin] = useState(props.singleDetail.socialMedia?.linkedin);
    const [instagram, setInstagram] = useState(props.singleDetail.socialMedia?.instagram);
    const [youtube, setYoutube] = useState(props.singleDetail.socialMedia?.youtube);
    const [photo, setPhoto] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');

    const uploadFunction = (file) => {
        const formData = new FormData();
        formData.append("file", file);
        tokenapi.post(uploadImg, formData)
        .then(res => {
            if(res.data.code === 200) {
                setPhoto(res.data.result);
            } else {
                setMessageType('error');
                setMessage(res.data.message);
            }
        })
        .catch(err => {
            console.error(err);
        })
    }

    const updateFunction = (e) => {
        e.preventDefault();
        setLoading(true);
        tokenapi.post(createVisitingCardQR, {
            qrType: props.singleDetail.qrType,
            requestId: props.singleDetail.requestId,
            categoryId: props.singleDetail.categoryId,
            title: title,
            name: name,
            about: about,
            organization: organization,
            designation: designation,
            phoneWork: phoneWork,
            phonePrivate: phonePrivate,
            landline: landline,
            fax: fax,
            email: email,
            website: addHttps(website),
            address: address,
            googleMap: addHttps(googleMap),
            photo: photo ? photo : props.singleDetail.photo,
            socialMedia: {
                facebook: addHttps(facebook),
                twitter: addHttps(twitter),
                linkedin: addHttps(linkedin),
                instagram: addHttps(instagram),
                youtube: addHttps(youtube)
            },
            s3Url: props.singleDetail.s3Url,
            encWebUrl: props.singleDetail.encWebUrl
        })
        .then(res => {
            setLoading(false);
            if(res.data.code === 200){
                props.fetchFunction();
                props.closeFunction();
                setMessageType('success');
            } else {
                setMessageType('error');
            }
            setMessage(res.data.message);
        })
        .catch(err => {
            setLoading(false);
            console.error(err);
        })
    }

    return (<>
        {message && <Message type={messageType} message={message} closeMessage={() => setMessage('')} />}
        <div className={styles["vcard-static-content"]}>
            <div>
                <img src={singleDetail.s3Url} alt="qr" />
            </div>
            <div>
                <p><span>Category:</span> {singleDetail.categoryName}</p>
                <p><span>Created on:</span> {`${new Date(singleDetail.createdAt)}`.substr(0, 24)}</p>
                <button className="btn btn-primary" onClick={() => downloadQr(singleDetail.s3Url)}><span className="fa fa-download"></span> Download QR</button>
            </div>
        </div>
        <form onSubmit={updateFunction}>
            <div className={styles["vcard-editable-content"]}>
                <p className={styles["modal-subheading"]}>Edit details</p>
                <label>Title</label>
                <input type="text" placeholder="Enter here" value={title} onChange={(e) => setTitle(e.target.value)} required/>
                <label>Name</label>
                <input type="text" placeholder="Enter here" value={name} onChange={(e) => setName(e.target.value)} required/>
                <label>About</label>
                <input type="text" placeholder="Enter here" value={about} onChange={(e) => setAbout(e.target.value)} required/>
                <label>Organization</label>
                <input type="text" placeholder="Enter here" value={organization} onChange={(e) => setOrganization(e.target.value)}/>
                <label>Designation</label>
                <input type="text" placeholder="Enter here" value={designation} onChange={(e) => setDesignation(e.target.value)}/>
                <label>Phone (Work)</label>
                <input type="text" placeholder="Enter here" value={phoneWork} onChange={(e) => setPhoneWork(e.target.value)}/>
                <label>Phone (Private)</label>
                <input type="text" placeholder="Enter here" value={phonePrivate} onChange={(e) => setPhonePrivate(e.target.value)}/>
                <label>Landline</label>
                <input type="text" placeholder="Enter here" value={landline} onChange={(e) => setLandline(e.target.value)}/>
                <label>Fax</label>
                <input type="text" placeholder="Enter here" value={fax} onChange={(e) => setFax(e.target.value)}/>
                <label>Email</label>
                <input type="text" placeholder="Enter here" value={email} onChange={(e) => setEmail(e.target.value)}/>
                <label>Website</label>
                <input type="text" placeholder="Enter here" value={website} onChange={(e) => setWebsite(e.target.value)}/>
                <label>Address</label>
                <input type="text" placeholder="Enter here" value={address} onChange={(e) => setAddress(e.target.value)}/>
                <label>Google Map</label>
                <input type="text" placeholder="Enter here" value={googleMap} onChange={(e) => setGoogleMap(e.target.value)}/>
                <label>Photo</label>
                {props.singleDetail.photo && <span className={styles['label-action']}><a href={props.singleDetail.photo} target='_blank' rel="noreferrer">view</a></span>}
                {
                    photo
                    ?<input type="text" defaultValue="File uploaded" disabled/>
                    :<input type="file" accept=".png,.jpg,.jpeg" onChange={e => uploadFunction(e.target.files[0])} />
                }
                <label>Facebook</label>
                <input type="text" placeholder="Enter here" value={facebook} onChange={(e) => setFacebook(e.target.value)}/>
                <label>Twitter</label>
                <input type="text" placeholder="Enter here" value={twitter} onChange={(e) => setTwitter(e.target.value)}/>
                <label>Linkedin</label>
                <input type="text" placeholder="Enter here" value={linkedin} onChange={(e) => setLinkedin(e.target.value)}/>
                <label>Instagram</label>
                <input type="text" placeholder="Enter here" value={instagram} onChange={(e) => setInstagram(e.target.value)}/>
                <label>Youtube</label>
                <input type="text" placeholder="Enter here" value={youtube} onChange={(e) => setYoutube(e.target.value)}/>
                <button className="btn btn-primary mt-3" disabled={loading}>{loading ? 'Wait ...' : 'Submit'}</button>
            </div>
        </form>
    </>)
}