import React, { useState } from 'react';
import tokenapi, { createUpiQr } from '../../../Base/TokenConfig';
import { downloadQr } from '../../../Utils/downloadQr';
import Message from '../../../Layouts/Message/Message';
import styles from '../my-qr-codes.module.css';

export default function UPIDetails(props){
    const singleDetail = props.singleDetail;
    const [title, setTitle] = useState(props.singleDetail.title);
    const [upiId, setUpiId] = useState(props.singleDetail.upiId);
    const [name, setName] = useState(props.singleDetail.name);
    const [amount, setAmount] = useState(props.singleDetail.amount);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');

    const updateFunction = (e) => {
        e.preventDefault();
        setLoading(true);
        tokenapi.post(createUpiQr, {
            qrType: props.singleDetail.qrType,
            categoryId: props.singleDetail.categoryId,
            requestId: props.singleDetail.requestId,
            title: title,
            upiId: upiId,
            name: name,
            amount: amount,
            s3Url: props.singleDetail.s3Url,
            encWebUrl: props.singleDetail.encWebUrl
        })
        .then(res => {
            setLoading(false);
            if(res.data.code === 200){
                props.fetchFunction();
                props.closeFunction();
                setMessageType('success');
            } else {
                setMessageType('error');
            }
            setMessage(res.data.message);
        })
        .catch(err => {
            setLoading(false);
            console.error(err);
        })
    }

    return (<>
        {message && <Message type={messageType} message={message} closeMessage={() => setMessage('')} />}
        <div className={styles["vcard-static-content"]}>
            <div>
                <img src={singleDetail.s3Url} alt="qr" />
            </div>
            <div>
                <p><span>Category:</span> {singleDetail.categoryName}</p>
                <p><span>Created on:</span> {`${new Date(singleDetail.createdAt)}`.substr(0, 24)}</p>
                <button className="btn btn-primary" onClick={() => downloadQr(singleDetail.s3Url)}><span className="fa fa-download"></span> Download QR</button>
            </div>
        </div>
        <form onSubmit={updateFunction}>
            <div className={styles["vcard-editable-content"]}>
                <p className={styles["modal-subheading"]}>Edit details</p>
                <label>Title</label>
                <input type="text" placeholder="Enter here" value={title} onChange={(e) => setTitle(e.target.value)} required/>
                <label>UPI ID</label>
                <input type="text" placeholder="Enter here" value={upiId} onChange={(e) => setUpiId(e.target.value)} required/>
                <label>Name</label>
                <input type="text" placeholder="Enter here" value={name} onChange={(e) => setName(e.target.value)} required/>
                <label>Amount</label>
                <input type="text" placeholder="Enter here" value={amount} onChange={(e) => setAmount(e.target.value)} required/>
                <button className="btn btn-primary mt-3" disabled={loading}>{loading ? 'Wait ...' : 'Submit'}</button>
            </div>
        </form>
    </>)
}