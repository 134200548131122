export const getToken = () => {
    return localStorage.getItem('token') || null;
}

export const setSession = (token) => {
    localStorage.setItem('token', token);
}

export const removeSession = () => {
    localStorage.removeItem('token');
}

export const setAdminToken = (token) => {
    return sessionStorage.setItem('auth', token);
}

export const getAdminToken = () => {
    return sessionStorage.getItem('auth') || null;
}

export const removeAdminToken = () => {
    return sessionStorage.removeItem('auth');
}

export const setCategory = (value) => {
    return sessionStorage.setItem('category', value);
}

export const getCategory = () => {
    return sessionStorage.getItem('category') || null;
}

export const removeCategory = () => {
    return sessionStorage.removeItem('category');
}

export const setQrDetail = (detail) => {
    return sessionStorage.setItem('qr-detail', JSON.stringify(detail));
}

export const getQrDetail = () => {
    const detail = sessionStorage.getItem('qr-detail');
    if(detail) {
        return JSON.parse(detail);
    } else {
        return null;
    }
}

export const removeQrDetail = () => {
    return sessionStorage.removeItem('qr-detail');
}

export const setUserDetail = (detail) => {
    return sessionStorage.setItem('user-detail', JSON.stringify(detail));
}

export const getUserDetail = () => {
    const detail = sessionStorage.getItem('user-detail');
    if(detail) {
        return JSON.parse(detail);
    } else {
        return null;
    }
}

export const removeUserDetail = () => {
    return sessionStorage.removeItem('user-detail');
}