import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import tokenapi, { getUserById } from '../../Base/TokenConfig';
import { getQrDetail } from '../../Utils/Common';
import QrDetails from './Components/QrDetails';
import VisitorDetails from './Components/VisitorDetails';
import styles from './my-qr-code-detail.module.css';
import QRAnalytics from './Components/QRAnalytics';
import Navbar from '../Components/Navbar/Navbar';

export default function MyQRCodeDetail(){
    const navigate = useNavigate();
    const qrDetail = getQrDetail();
    const [tabName, setTabName] = useState('qr-details');
    const [phoneNumber, setPhoneNumber] = useState('');

    useEffect(() => {
        if(!qrDetail) {
            navigate('/my-qr-codes');
        }
        fetchFunction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchFunction = () => {
        tokenapi.get(getUserById)
        .then(res => {
            // setLoader(false);
            if(res.data.code === 200){
                // setProfileDetails(res.data.result);
                setPhoneNumber(res.data.result.phone);
            }
        })
        .catch(err => {
            // setLoader(false);
            console.error(err);
        })
    }

    const PhoneNotFound = () => {
        return (
            <div className={styles['verify-phone']}>
                <img src="./assets/images/verify-phone.png" alt="verify-phone" />
                <p>To access these details, please add your phone number. <span>This ensures a secure and personalized experience. Thank you for your cooperation!</span></p>
                <p><span>You can add your phone from <Link to="/profile">here</Link>.</span></p>
            </div>
        )
    }

    return (<>
        <Navbar heading='QR Codes Detail' subheading='View QR and Visitor Details' />
        <div className="dashboard-content">
            <ul className={styles['tabs']}>
                <li className={styles[tabName === 'qr-details' ? 'selected' : '']} onClick={() => setTabName('qr-details')}>QR details</li>
                <li className={styles[tabName === 'visitor-details' ? 'selected' : '']} onClick={() => setTabName('visitor-details')}>Visitor details</li>
                <li className={styles[tabName === 'qr-analytics' ? 'selected' : '']} onClick={() => setTabName('qr-analytics')}>QR analytics</li>
            </ul>
            {tabName === 'qr-details' && <QrDetails data={qrDetail} />}
            {tabName === 'visitor-details' && (phoneNumber ? <VisitorDetails data={qrDetail} /> : <PhoneNotFound />)}
            {tabName === 'qr-analytics' && (phoneNumber ? <QRAnalytics data={qrDetail} /> : <PhoneNotFound />)}
        </div>
    </>)
}
