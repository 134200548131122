import axios from "axios";
export const baseurl = process.env.REACT_APP_BASEURL;

const api = axios.create({
    baseURL: baseurl,
    headers: {
        'token': process.env.REACT_APP_TOKEN,
        'versionnumber': process.env.REACT_APP_VERSION
    },
});

export default api;

// Get user details and token with email
export const loginWithGoogle = '/user/loginWithGoogle';

// Send OTP Login
export const loginsendOtp = '/login/sendOtp';

// Verify Login OTP
export const loginverifyOtp = '/login/verifyOtp';

// Login user
export const userLogin = '/user/login';

// Registration user
export const userRegistration = '/user/registration';

// Admin login API
export const adminLogin = '/admin/authenticate/v1';

// Contact form API (using in footer)
export const contactUs = '/user/contactUs';

// Verify email for a new registered user
export const verifyEmail = '/user/verifyEmail';

// Send forgot password link on email
export const sendForgotPasswordEmail = '/user/forgotPassword';

// Change password from link sent on email
export const changePassword = '/user/setPassword';

// Contact form API (using in contact us page)
export const contactUsQuery = '/user/contactUsQuery';

// register user from Google
export const registrationGoogle = '/user/registrationGoogle';

// Fetch Vcard details
export const getVcard = '/getVcard/';

// Fetch website url
export const getWebsite = '/user/getQrWeb/';

// Get WhatsApp message
export const getWhatsAppQr = '/user/getWhatsAppQr/';

// Get Text and description
export const getQrText = '/user/getQrText/';

// Get Media url
export const getQrMedia = '/user/getQrMedia/';

// Get PDF url
export const getPdfQr = '/user/getPdfQr/';

// Get Location details
export const getGoogleMapsQr = '/user/getGoogleMapsQr/';