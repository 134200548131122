import React from 'react';
import { Link } from 'react-router-dom';
import styles from './blog.module.css';

export default function Blog(){
    return (<>
        <div style={{height:'100px'}}></div>
        <div className="container">
            <div className={styles['blog-container']}>
                <div>
                    <div className={styles['blog-box']}>
                        <img src="../assets/images/blog/how-qr-codes-work-and-decode-information.png" alt="how-qr-codes-work-and-decode-information" />
                        <h5 className={styles['blog-category']}>Customised Demystifying the Technology</h5>
                        <h2 className={styles['blog-title']}>Customised Demystifying the Technology: A Comprehensive Guide to How QR Codes Work and Decode Information</h2>
                        <h6 className={styles['blog-date']}>December 1st, 2024</h6>
                        <p className={styles['blog-content']}>In an increasingly digital world, QR codes (Quick Response codes) have become a vital tool for bridging the gap between the physical and virtual. From accessing menus at restaurants to facilitating mobile payments and even enhancing marketing campaigns, QR codes have a vast array of applications. But what exactly is a QR code, how does it work, and why has it become so popular? This blog dives into the fundamentals of QR codes, their working mechanisms, and their diverse use cases.</p>
                        <Link to="/blog/how-qr-codes-work-and-decode-information"><span className={styles['blog-link']}>Read More</span></Link>
                    </div>
                    <div className={styles['blog-box']}>
                        <img src="../assets/images/blog/creating-qrcode-for-your-website.png" alt="creating-qrcode-for-your-website" />
                        <h5 className={styles['blog-category']}>Elevate Your Digital Presence</h5>
                        <h2 className={styles['blog-title']}>Elevate Your Digital Presence: A Step-by-Step Guide to Creating a QR Code for Your Website</h2>
                        <h6 className={styles['blog-date']}>November 24th, 2024</h6>
                        <p className={styles['blog-content']}>Creating a QR code is simple and requires no technical expertise. Here’s a step-by-step guide: 1. Choose a QR Code Generator: There are many online QR code generators available, such as: Free Tools: Scanonn, QRStuff, or QRtiger. Advanced Tools: Platforms like Scanonn or qrtiger offer dynamic QR codes with analytics features.</p>
                        <Link to="/blog/creating-qrcode-for-your-website"><span className={styles['blog-link']}>Read More</span></Link>
                    </div>
                    <div className={styles['blog-box']}>
                        <img src="../assets/images/blog/qrcode-scanner.webp" alt="paper-to-pixel" />
                        <h5 className={styles['blog-category']}>From Paper to Pixels</h5>
                        <h2 className={styles['blog-title']}>From Paper to Pixels: Why QR Code Scanners Are the Future of Data Sharing</h2>
                        <h6 className={styles['blog-date']}>November 4th, 2024</h6>
                        <p className={styles['blog-content']}>In today’s fast-paced, digital-driven world, data sharing has moved beyond traditional paper forms, business cards, and printed menus. We’re now in an era where a quick scan can transfer information instantly. QR code technology has rapidly evolved as a powerful tool for seamless data sharing, and it’s never been easier to create and use them, thanks to tools like QR code scanner online free options and a free QR code generator.</p>
                        <Link to="/blog/paper-to-pixel"><span className={styles['blog-link']}>Read More</span></Link>
                    </div>
                    <div className={styles['blog-box']}>
                        <img src="../assets/images/blog/environmental-impact-of-qr-codes.jpeg" alt="paper-to-pixel" />
                        <h5 className={styles['blog-category']}>Environmental Impact</h5>
                        <h2 className={styles['blog-title']}>The Environmental Impact of QR Codes: How Digital Solutions Reduce Waste</h2>
                        <h6 className={styles['blog-date']}>October 17, 2024</h6>
                        <p className={styles['blog-content']}>As the world moves toward more sustainable practices, digital solutions like QR codes are playing an increasingly pivotal role in reducing waste and minimising environmental impact. QR codes, created with tools such as a QR code generator or QR code maker, offer businesses and consumers alike an eco-friendly alternative to paper, ink, and plastic-heavy materials traditionally used in marketing, transactions, and information sharing. In this blog, we’ll delve into how QR codes are driving sustainability efforts and why their usage is more relevant than ever.</p>
                        <Link to="/blog/environmental-impact-of-qr-codes"><span className={styles['blog-link']}>Read More</span></Link>
                    </div>
                    <div className={styles['blog-box']}>
                        <img src="../assets/images/blog/qr-code-scanners-in-education.jpeg" alt="qr-code-scanners-in-education" />
                        <h5 className={styles['blog-category']}>How to generate QR code</h5>
                        <h2 className={styles['blog-title']}>QR Code Scanners in Education: How They're Transforming Learning Environments</h2>
                        <h6 className={styles['blog-date']}>October 10, 2024</h6>
                        <p className={styles['blog-content']}>In the ever-evolving world of education, technology is playing a key role in reshaping how students learn and teachers teach. One such technology making a significant impact is the QR code scanner. From streamlining administrative tasks to enhancing student engagement, QR codes are transforming learning environments. Whether you’re using a free QR code generator from Scanonn or a reliable other QR code maker , this simple tool can make a big difference in the classroom.</p>
                        <Link to="/blog/qr-code-scanners-in-education"><span className={styles['blog-link']}>Read More</span></Link>
                    </div>
                </div>
                <div className={styles['blog-suggestions']}>
                    <h5 className={styles['latest-blogs']}>Latest blogs</h5>
                    <Link to="/blog/how-qr-codes-work-and-decode-information">
                        <div>
                            <img src="../assets/images/blog/how-qr-codes-work-and-decode-information.png" alt="how-qr-codes-work-and-decode-information" />
                            <p>Customised Demystifying the Technology: A Comprehensive Guide to How QR Codes Work and Decode Information</p>
                        </div>
                    </Link>
                    <Link to="/blog/creating-qrcode-for-your-website">
                        <div>
                            <img src="../assets/images/blog/creating-qrcode-for-your-website.png" alt="creating-qrcode-for-your-website" />
                            <p>Elevate Your Digital Presence: A Step-by-Step Guide to Creating a QR Code for Your Website</p>
                        </div>
                    </Link>
                    <Link to="/blog/paper-to-pixel">
                        <div>
                            <img src="../assets/images/blog/qrcode-scanner.webp" alt="paper-to-pixel" />
                            <p>From Paper to Pixels: Why QR Code Scanners Are the Future of Data Sharing</p>
                        </div>
                    </Link>
                    <Link to="/blog/environmental-impact-of-qr-codes">
                        <div>
                            <img src="../assets/images/blog/environmental-impact-of-qr-codes.jpeg" alt="environmental-impact-of-qr-codes" />
                            <p>The Environmental Impact of QR Codes: How Digital Solutions Reduce Waste</p>
                        </div>
                    </Link>
                    <Link to="/blog/qr-code-scanners-in-education">
                        <div>
                            <img src="../assets/images/blog/qr-code-scanners-in-education.jpeg" alt="qr-code-scanners-in-education" />
                            <p>QR Code Scanners in Education: How They're Transforming Learning Environments</p>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    </>)
}
