import React, { useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import Message from '../../../Layouts/Message/Message';
import countryCodes from '../../../Common/countryCodes.json';
import api, { userRegistration, registrationGoogle } from '../../../Base/Config';
import styles from '../login.module.css';

export default function Signup(props){
    const [ firstName, setFirstName ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ countryCode, setCountryCode ] = useState('+91');
    const [ phone, setPhone ] = useState('');
    const [ industry, setIndustry ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ showPassword, setShowPassword ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ message, setMessage ] = useState('');
    const [ messageType, setMessageType ] = useState('');
    const [ googleLoader, setGoogleLoader ] = useState(false);

    const submitFunction = (e) => {
        e.preventDefault();
        setLoading(true);
        api.post(userRegistration, {
            firstName: firstName,
            lastName: lastName,
            countryCode: countryCode,
            phone: phone,
            email: email,
            password: password,
            industry: industry
        })
        .then(res => {
            setLoading(false);
            setMessage(res.data.message);
            if(res.data.code === 200) {
                setMessageType('success');
            } else {
                setMessageType('error');
            }
        })
        .catch(err => {
            console.error(err);
        })
    }

    const login = useGoogleLogin({
        onSuccess: async response => {
            try {
                googleAuth(response.access_token);
            } catch (error) {
                console.error(error);
            }
        }
    });

    const googleAuth = (token) => {
        setGoogleLoader(true);
        api.post(registrationGoogle, {
            token: token
        })
        .then(res => {
            setGoogleLoader(false);
            setMessage(res.data.message);
            if(res.data.code === 200) {
                setMessageType('success');
                setTimeout(() => {
                    props.changeFunction(false);
                }, 2000);
            } else {
                setMessageType('error');
                setMessage(res.data.message);
            }
        })
        .catch(err => {
            setGoogleLoader(false);
            console.error(err);
        })
    }

    return (<>
        {googleLoader && <div className={styles['google-loader']}>
            <div>Signing up. Please wait.</div>
        </div>}
        {message && <Message type={messageType} message={message} closeMessage={() => setMessage('')} />}
        <form onSubmit={submitFunction}>
            <h1>Welcome</h1>
            <label>FIRST NAME*</label>
            <input type="text" placeholder="Enter first name" value={firstName} onChange={(e) => setFirstName(e.target.value)} required/>
            <label>LAST NAME*</label>
            <input type="text" placeholder="Enter last name" value={lastName} onChange={(e) => setLastName(e.target.value)} required/>
            <label>PHONE*</label>
            <div className='phone-input'>
                <select value={countryCode} onChange={(e) => setCountryCode(e.target.value)} required>
                    {countryCodes.map((item, i) => (
                        <option value={item.dial_code} key={i}>{item.dial_code}</option>
                    ))}
                </select>
                <input type="text" placeholder="Enter phone number" value={phone} onChange={(e) => setPhone(e.target.value)} required/>
            </div>
            <label>INDUSTRY*</label>
            <select onChange={(e) => setIndustry(e.target.value)} required>
                <option value="">-- Select one --</option>
                <option value="Agriculture & Forestry">Agriculture & Forestry</option>
                <option value="Automotive">Automotive</option>
                <option value="Banking & Finance">Banking & Finance</option>
                <option value="Construction & Real Estate">Construction & Real Estate</option>
                <option value="Consumer Goods">Consumer Goods</option>
                <option value="Education & Training">Education & Training</option>
                <option value="Energy & Utilities">Energy & Utilities</option>
                <option value="Entertainment & Media">Entertainment & Media</option>
                <option value="Healthcare & Pharmaceuticals">Healthcare & Pharmaceuticals</option>
                <option value="Hospitality & Tourism">Hospitality & Tourism</option>
                <option value="Information Technology (IT)">Information Technology (IT)</option>
                <option value="Logistics & Transportation">Logistics & Transportation</option>
                <option value="Manufacturing">Manufacturing</option>
                <option value="Non-Profit & Charities">Non-Profit & Charities</option>
                <option value="Professional Services">Professional Services</option>
                <option value="Retail & E-Commerce">Retail & E-Commerce</option>
                <option value="Telecommunications">Telecommunications</option>
                <option value="Government & Public Services">Government & Public Services</option>
                <option value="Sports & Recreation">Sports & Recreation</option>
                <option value="Utilities & Public Infrastructure">Utilities & Public Infrastructure</option>
                <option value="Beauty & Personal Care">Beauty & Personal Care</option>
                <option value="Art & Design">Art & Design</option>
                <option value="Events & Exhibitions">Events & Exhibitions</option>
                <option value="Security & Defense">Security & Defense</option>
                <option value="Others">Others</option>
            </select>
            <label>EMAIL ADDRESS*</label>
            <input type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
            <label>PASSWORD*</label>
            <div className={styles['hide-show']}>
                <input type={showPassword ? 'text' : 'password'} placeholder="Enter password" autoComplete="new-password" value={password} onChange={(e) => setPassword(e.target.value)} required/>
                <div onClick={() => setShowPassword(!showPassword)}><span className={`fa fa-${showPassword ? 'eye' : 'eye-slash'}`}></span></div>
            </div>
            <button className="button black-button" disabled={loading}>{loading ? 'Wait ...' : 'Submit'}</button>
        </form>
        <p className={styles['signin']}>Already a user? <span onClick={() => props.changeFunction(false)}>Sign in</span> now</p>
        <div className={styles['or']}>
            <p className={styles['or-line']}></p>
            <p className={styles['or-text']}><span>OR</span></p>
        </div>
        <div className={styles['google-auth']} onClick={() => login()}>
            <img src="./assets/images/google-icon.svg" alt="google-icon" />
            <span>Sign up with Google</span>
        </div>
    </>)
}
