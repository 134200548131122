import React, { useState, useEffect, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { QRCode as ReactQRCode } from 'react-qrcode-logo';
import { getToken, setCategory } from '../../Utils/Common';
import { addHttps } from '../../Utils/addHttps';
import eyeRadius from '../../Common/eyeRadius.json';
import logos from '../../Common/logos.json';
import Modal from '../../Layouts/Modal/Modal';
import Signup from '../../User/Login/Components/Signup';
import Signin from '../../User/Login/Components/Signin';
import CreateManageQR from '../Components/CreateManageQR/CreateManageQR';
import { faqs } from '../Components/FAQs';
import styles from './qr-code.module.css';

export default function QRCode(){
    const token = getToken();
    const navigate = useNavigate();
    const tabNames = ["Website", "Text", "PDF", "Visiting Card", "WhatsApp", "Media", "Location"];
    const [qrTitle, setQrTitle] = useState('');
    const [url, setUrl] = useState('');
    const [qrType, setQrType] = useState('static');
    const [ecLevel, setEcLevel] = useState('L');
    const [qrStyle, setQrStyle] = useState('squares');
    const [qrTabName, setQrTabName] = useState('type');
    const [fgColor, setFgColor] = useState('#000000');
    const [eyeColor, setEyeColor] = useState('#000000');
    const [logoOpacity, setLogoOpacity] = useState('1');
    const [logoPadding, setLogoPadding] = useState('0');
    const [logoPaddingStyle, setLogoPaddingStyle] = useState('square');
    const [selectedEye, setSelectedEye] = useState(eyeRadius[0]);
    const [selectedLogo, setSelectedLogo] = useState('');
    const [signupModal, setSignupModal] = useState(false);
    const [registration, setRegistration] = useState(false);
    const [activeFaq, setActiveFaq] = useState(1);
    const [tabCounts, setTabCounts] = useState('');

    const changeFunction = (x) => setRegistration(x);

    const tabChangeFunction = (i) => {
        if(i !== 0) {
            if(token) {
                navigate('/create-qr-code');
            } else {
                setSignupModal(true);
            }
        }
    }

    const generateQrCodeFunction = () => {
        setCategory(JSON.stringify({
            "categoryName": "URL",
            "categoryId": process.env.REACT_APP_BASEURL === 'https://api.scanonn.com' ? 'rf5mgjvutr' : 'cfofb1964y',
            "qrType": qrType
        }));
        sessionStorage.setItem('qr-content', JSON.stringify({ title: qrTitle, url: addHttps(url) }));
        sessionStorage.setItem('removeDetails', false);
        if(token) {
            navigate('/create-qr-code');
        } else {
            setSignupModal(true);
        }
    }

    const handleResize = () => {
        const windowWidth = window.innerWidth;
        if(windowWidth >= 1200) {
            setTabCounts(7);
        } else if(windowWidth >= 991 && windowWidth < 1200) {
            setTabCounts(6);
        } else if(windowWidth >= 767 && windowWidth < 991) {
            setTabCounts(5);
        } else if(windowWidth >= 500 && windowWidth < 767) {
            setTabCounts(4);
        } else if(windowWidth >= 350 && windowWidth < 500) {
            setTabCounts(3);
        } else if(windowWidth < 350) {
            setTabCounts(2);
        }
    };

    useEffect(() => {
        handleResize();
    }, []);

    return (<>
        <Helmet>
            <title>QR Code Generator | Create Your Free QR Codes</title>
            <meta name="description" content="Easily create custom QR codes for free! Generate codes for websites, pdf, Vcards, and more with our simple QR code generator. get started now!" />
        </Helmet>
        {signupModal && <Modal closeModal={() => setSignupModal(false)}>
            <div className={styles['signup-modal']}>
                <div className={styles['content']}>
                    <div>
                        <img src="./assets/images/times.png" alt="times" onClick={() => setSignupModal(false)} />
                        {registration ? <Signup changeFunction={changeFunction} /> : <Signin changeFunction={changeFunction} createPage={true} />}
                    </div>
                </div>
            </div>
        </Modal>}
        <div style={{height:'100px'}}></div>
        <section>
            <div className='container'>
                <h1 className={styles['heading']}>AI Integrated advanced QR Code Generator with logo</h1>
                <div className={`${styles['qrcode-tabs']} ${styles[`qrcode-${tabCounts}tabs`]}`}>
                    {tabNames.slice(0, (tabCounts !== 7 ? tabCounts - 1 : 7)).map((item, i) => (
                        <Fragment key={i}>
                            <div className={styles[i === 0 && 'active']} onClick={() => tabChangeFunction(i)}>{item}</div>
                        </Fragment>
                    ))}
                    {tabCounts !== 7 && <div onClick={() => tabChangeFunction()}>More...</div>}
                </div>
                <div className={styles['box']}>
                    <h2 className={styles['heading2']}>Choose QR Type</h2>
                    <div className={styles['qr-types']}>
                        <span className={styles[qrType === 'static' && 'active']} onClick={() => setQrType('static')}><input type="radio" name="type" checked={qrType === 'static'} /> Static</span>
                        <span className={styles[qrType === 'dynamic' && 'active']} onClick={() => setQrType('dynamic')}><input type="radio" name="type" checked={qrType === 'dynamic'} /> Dynamic</span>
                    </div>
                    <label>QR title</label>
                    <input type="text" placeholder="Enter qr title here" value={qrTitle} onChange={e => setQrTitle(e.target.value)} />
                    <label>URL</label>
                    <input type="text" placeholder="Enter url here" value={url} onChange={e => setUrl(e.target.value)} />
                </div>
                <div className={styles['box']}>
                    <h2 className={styles['heading2']}>Customize QR Code</h2>
                    <ul className={styles['tabs']}>
                        <li className={styles[qrTabName === 'type' && 'active']} onClick={() => setQrTabName('type')}>Type</li>
                        <li className={styles[qrTabName === 'qr-eyes' && 'active']} onClick={() => setQrTabName('qr-eyes')}>QR Eyes</li>
                        <li className={styles[qrTabName === 'logo' && 'active']} onClick={() => setQrTabName('logo')}>Logo</li>
                    </ul>
                    <div className={styles['customize-qr-code']}>
                        <div>
                            {qrTabName === 'type' && <>
                                <label>Correction level</label>
                                <select value={ecLevel} onChange={e => setEcLevel(e.target.value)}>
                                    <option value="L">Low</option>
                                    <option value="M">Medium</option>
                                    <option value="Q">Quartile</option>
                                    <option value="H">High</option>
                                </select>
                                <label>QR styles</label>
                                <select value={qrStyle} onChange={e => setQrStyle(e.target.value)}>
                                    <option value="squares">Squares</option>
                                    <option value="dots">Dots</option>
                                    <option value="fluid">Fluid</option>
                                </select>
                                <label>QR color</label>
                                <div className={styles['customize-qr-color']}>
                                    <input type="color" value={fgColor} onChange={e => setFgColor(e.target.value)} />
                                    <input type="text" placeholder="Enter qr title here" value={fgColor} onChange={e => setFgColor(e.target.value)} />
                                </div>
                                <label>Corner color</label>
                                <div className={styles['customize-qr-color']}>
                                    <input type="color" value={eyeColor} onChange={e => setEyeColor(e.target.value)} />
                                    <input type="text" placeholder="Enter qr title here" value={eyeColor} onChange={e => setEyeColor(e.target.value)} />
                                </div>
                            </>}
                            {qrTabName === 'qr-eyes' && <>
                                <div className={styles["qr-eyes"]}>
                                    {eyeRadius.map((item, i) => (
                                        <div className={styles[selectedEye.id === item.id && 'selected']} onClick={() => setSelectedEye(item)} key={i}><img src={`./assets/images/qr-eyes/${item.imageName}`} alt="qr-eye" /></div>
                                    ))}
                                </div>
                            </>}
                            {qrTabName === 'logo' && <>
                                <div className={styles["qr-logos"]}>
                                    {logos.map((item, i) => (
                                        <div className={styles[selectedLogo.logoId === item.logoId && 'selected']} onClick={() => setSelectedLogo(item)} key={i}><img src={item.logoUrl} alt="logo" /></div>
                                    ))}
                                    <div className={styles[!selectedLogo && 'selected']} onClick={() => setSelectedLogo('')}><img src="./assets/images/logos/none.png" alt="logo" /></div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-md-4">
                                        <label>Logo padding</label>
                                        <input type="number" value={logoPadding} onChange={e => setLogoPadding(e.target.value)} />
                                    </div>
                                    <div className="col-xs-12 col-md-4">
                                        <label>Logo opacity</label>
                                        <select value={logoOpacity} onChange={e => setLogoOpacity(e.target.value)}>
                                            <option value="1">1</option>
                                            <option value="0.9">0.9</option>
                                            <option value="0.8">0.8</option>
                                            <option value="0.7">0.7</option>
                                            <option value="0.6">0.6</option>
                                            <option value="0.5">0.5</option>
                                            <option value="0.4">0.4</option>
                                            <option value="0.3">0.3</option>
                                            <option value="0.2">0.2</option>
                                            <option value="0.1">0.1</option>
                                        </select>
                                    </div>
                                    <div className="col-xs-12 col-md-4">
                                        <label>Logo padding style</label>
                                        <select value={logoPaddingStyle} onChange={e => setLogoPaddingStyle(e.target.value)}>
                                            <option value="square">square</option>
                                            <option value="circle">circle</option>
                                        </select>
                                    </div>
                                </div>
                            </>}
                        </div>
                        <div>
                            <div className={styles['qr-box']}>
                                <h3>Your QR design</h3>
                                <div style={{width:'230px',display:'inline-block'}}>
                                    <ReactQRCode
                                        value={url} // string
                                        ecLevel={ecLevel} // L | M | Q | H
                                        enableCORS="true" // boolean
                                        size="1000" // number (in pixels)
                                        quietZone="5" // number (in pixels)
                                        bgColor="#FFFFFF" // string (css color)
                                        fgColor={fgColor} // string (css color)
                                        logoImage={selectedLogo.logoUrl} // string (src attribute)
                                        logoWidth={240} // number (in pixels)
                                        logoOpacity={logoOpacity} // number (css opacity 0 <= x <= 1)
                                        removeQrCodeBehindLogo={false} // boolean
                                        logoPadding={logoPadding} // number
                                        logoPaddingStyle={logoPaddingStyle} // square | circle
                                        qrStyle={qrStyle} // squares | dots | fluid
                                        eyeRadius={selectedEye.eyeRadius}
                                        eyeColor={eyeColor}
                                    />
                                </div>
                                <br/>
                                {
                                    url
                                    ?<button className="btn btn-primary" onClick={() => generateQrCodeFunction()}>Generate QR</button>
                                    :<button className="btn btn-primary" disabled>Generate QR</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className='container'>
                <div className={styles['about']}>
                    <div>
                        <img src="../assets/images/Qrcodegenerator.png" alt="free qr code generator" width="100%" />
                    </div>
                    <div>
                        <h2 className={styles['about-heading']}>Free QR code generator and tracking solutions</h2>
                        <p>Welcome to Scanonn, where we harness the power of Generative AI to revolutionize QR code generation and tracking solutions. Our mission is to empower businesses and individuals with an intuitive and reliable platform that enhances data tracking, boosts efficiency, and streamlines processes. </p>
                        <p>With our cutting-edge QR code generator, you can create custom codes in seconds and monitor valuable data in real-time. Our dedicated team of professionals is committed to delivering exceptional service and support, ensuring that you have the tools and expertise needed to achieve your goals.</p>
                        <p>Whether you're a small business owner, a marketing professional, or a healthcare provider, Scanonn is here to provide you with the innovative solutions you need to succeed in today's dynamic environment.</p>
                        <Link to="/product"><button className="btn btn-primary">Explore more</button></Link>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className='container'>
                <div className={styles['why-scanonn']}>
                    <div>
                        <h2>Why ScanOnn?</h2>
                        <p>Scanonn sets the benchmark as the leading QR code generator, leveraging Generative AI to provide a vital tool for businesses of all scales. Craft personalized QR codes embellished with your brand's logo, colors, and design elements. Embark on this journey with our complimentary trial.</p>
                        <div className={styles['why-scanonn-boxes']}>
                            <div>
                                <img src="../assets/images/generate-qr.png" alt="generate-qr" width="100%" />
                                <p>Enhanced QR Performance</p>
                                <span>Optimize QR code performance with our AI solution.</span>
                            </div>
                            <div>
                                <img src="../assets/images/campaign-folders.png" alt="campaign-folders" width="100%" />
                                <p>Unlimited QR Creation</p>
                                <span>Create unlimited QR codes quickly and easily.</span>
                            </div>
                            <div>
                                <img src="../assets/images/bulk-creation.png" alt="bulk-creation" width="100%" />
                                <p>Multi-User Support</p>
                                <span>Collaborate with ease using multiple user accounts.</span>
                            </div>
                            <div>
                                <img src="../assets/images/scan-statistics.png" alt="scan-statistics" width="100%" />
                                <p>Unlimited Scans</p>
                                <span>Reach more people with unlimited QR code scans.</span>
                            </div>
                            <div>
                                <img src="../assets/images/design-options.png" alt="design-options" width="100%" />
                                <p>Comprehensive Analytics</p>
                                <span>Gain insights with AI-driven monitoring and analytics.</span>
                            </div>
                            <div>
                                <img src="../assets/images/dynamic-qr-codes.png" alt="dynamic-qr-codes" width="100%" />
                                <p>White Labeling</p>
                                <span>Personalize QR codes with your brand using CNAME.</span>
                            </div>
                            <div>
                                <img src="../assets/images/dynamic-qr-codes.png" alt="dynamic-qr-codes" width="100%" />
                                <p>Industry Templates</p>
                                <span>Choose from templates tailored to your industry.</span>
                            </div>
                            <div>
                                <img src="../assets/images/dynamic-qr-codes.png" alt="dynamic-qr-codes" width="100%" />
                                <p>Customization Support</p>
                                <span>Integrate and customize with expert support.</span>
                            </div>
                            <div>
                                <img src="../assets/images/dynamic-qr-codes.png" alt="dynamic-qr-codes" width="100%" />
                                <p>No-Code Platform</p>
                                <span>Expand your business effortlessly with our platform.</span>
                            </div>
                        </div>
                        <Link to="/product"><button className="btn btn-primary">Explore more</button></Link>
                    </div>
                    <div>
                        <img src="../assets/images/why-scanonn.png" alt="why-scanonn" width="100%" />
                    </div>
                </div>
            </div>
        </section>
        <section id={styles['free-qr-generator']}>
            <div className="container">
                <h2>Create Dynamic QR Code in Minutes for Free</h2>
                <div className={styles['free-qr-generator']}>
                    <div>
                        <img src="../assets/images/set-qr-content.png" alt="set-qr-content" width="100%" />
                        <p>Generate Your QR Code Quickly</p>
                        <span>Create your custom QR code swiftly and easily with Plagrams Free.</span>
                    </div>
                    <div>
                        <img src="../assets/images/customize-design.png" alt="customize-design" width="100%" />
                        <p>Choose Your QR Code Content</p>
                        <span>Select from a diverse range of content options including PDFs, menus, videos, business cards, websites, apps, and more.</span>
                    </div>
                    <div>
                        <img src="../assets/images/generate-qrcode.png" alt="generate-qrcode" width="100%" />
                        <p>Customize and Design Options</p>
                        <span>Tailor your QR code to your liking by entering your desired information and utilizing our QR code generator to create a unique design.</span>
                    </div>
                    <div>
                        <img src="../assets/images/get-report.png" alt="report" width="100%" />
                        <p>Download Your QR Code</p>
                        <span>Obtain your QR code in various formats such as PDF, PNG, and SVG. Print it or display it digitally, and you're all set!</span>
                    </div>
                </div>
            </div>
        </section>
        <section id={styles['create-manage']}>
            <CreateManageQR />
        </section>
        <section id={styles['faqs']}>
            <div className='container'>
                <h2>Frequently Asked Questions</h2>
                <div className={styles['faqs']}>
                    <div>
                        {faqs.map((item, i) => (
                            <div className={styles['faq-blocks']} key={i}>
                                <div onClick={() => setActiveFaq(item.id)}>
                                    <i className={`fa fa-${activeFaq === item.id?"minus":"plus"}-circle float-right`}></i>
                                    <p className={styles['faq-question']}>0{item.id} <span> {item.question}</span></p>
                                </div>
                                <div className={`collapse ${activeFaq === item.id?"show":"hide"}`}>
                                    <div className={styles['faq-answers']}>
                                        {item.answer}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div>
                        <img src="../assets/images/faq.png" alt="faq" width="100%" />
                    </div>
                </div>
            </div>
        </section>
    </>)
}
