import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import adminapi, { fetchAllUsers, adminFilter } from '../../Base/AdminConfig';
import { setUserDetail } from '../../Utils/Common';
import styles from './users.module.css';

export default function Users(){
    const navigate = useNavigate();
    const [ users, setUsers ] = useState([]);
    const [ filterModal, setFilterModal ] = useState(false);
    const [ firstName, setFirstName ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ phone, setPhone] = useState('');
    const [ userId, setUserId ] = useState('');
    const [ customerId, setCustomerId ] = useState('');
    const [ filterList, setFilterList ] = useState([]);
    const [ filterLoading, setFilterLoading ] = useState(false);
    const [ filterData, setFilterData ] = useState(false);

    useEffect(() => {
        fetchUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchUsers = () => {
        adminapi.get(fetchAllUsers)
        .then(res => {
            if(res.data.code === 200){
                setUsers(res.data.result);
            } else {
                setUsers([]);
            }
        })
        .catch(err => {
            console.error(err);
        })
    }

    function openDetail(item) {
        setUserDetail(item);
        navigate('/admin/user-detail');
    }

    const filterfunction = (e) => {
        e.preventDefault();
        setFilterLoading(true);
        adminapi.get(adminFilter + `firstName=${firstName}&lastName=${lastName}&email=${email}&phone=${phone}&userId=${userId}&customerId=${customerId}`)
        .then((res) => {
            setFilterLoading(false);
            setFilterModal(false);
            if(res.data.code === 200) {
                setFilterData(true);
                setFilterList(res.data.results);
            } else {
                setFilterList([]);
            }
        })
        .catch((error) => {
            console.error(error);
            setFilterLoading(false);
        })
    }

    const clearFilter = () => {
        setFilterData(false);
        setFilterModal(false);
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setUserId('');
        setCustomerId('');
    }

    return (<>
        {filterModal && <div className={styles['filter-container']}>
            <div className={styles['filter-background']} onClick={() => setFilterModal(false)}></div>
            <div className={styles['filter-form']}>   
                <div className={styles['filter-icon']}>
                    <span>Filter</span>
                    <img src="../assets/images/times.png" alt="times" onClick ={() => setFilterModal(false)} />
                </div>
                <hr/>
                <form onSubmit={filterfunction}>
                    <label>First name</label>
                    <input type="text" placeholder="Enter first name" value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                    <label>Last name</label>
                    <input type="text" placeholder="Enter last name" value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                    <label>Email</label>
                    <input type="text" placeholder="Enter Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                    <label>Phone</label>
                    <input type="text" placeholder="Enter Phone" value={phone} onChange={(e) => setPhone(e.target.value)}/>
                    <label>User id</label>
                    <input type="text" placeholder="Enter user id" value={userId} onChange={(e) => setUserId(e.target.value)}/>
                    <label>Customer id</label>
                    <input type="text" placeholder="Enter customer id" value={customerId} onChange={(e) => setCustomerId(e.target.value)}/>
                    <p className={styles["clear-filter"]} onClick={() => clearFilter()}>Clear</p>
                    {
                        filterLoading
                        ?<button className="btn btn-primary" disabled><i className="fa fa-circle-o-notch fa-spin"></i> Wait</button>
                        :<button type="submit" className="btn btn-primary">Apply</button>
                    }
                </form>
            </div>
        </div>}
        <div className={styles['title-filter-button']}>
            <h1>Users <span>({users.length})</span></h1>
            <button className="btn btn-primary" onClick={() => setFilterModal(true)}>Filter</button>
        </div>
        <div className={styles['users-list']}>
            <div>
                <div><p>#</p></div>
                <div className={styles['main-details']}>
                    <p>Created on</p>
                    <p>Name</p>
                    <p>Email</p>
                    <p>Phone</p>
                    <p>Type</p>
                    <p>Plan</p>
                    <p>Status</p>
                </div>
                <div><p>Details</p></div>
            </div>
            {
                filterData
                ?<>
                    {
                        filterList.length > 0
                        ?filterList.map((item, i) => (
                            <div key={item._id}>
                                <div><p><span>#</span> {i + 1}</p></div>
                                <div className={styles['main-details']}>
                                    <p><span>Created on</span> {`${new Date(item.createdAt)}`.substr(0, 24)}</p>
                                    <p><span>Name</span> {item.firstName + " " + item.lastName}</p>
                                    <p><span>Email</span> {item.email}</p>
                                    <p><span>Phone</span> {item.phone}</p>
                                    <p><span>Type</span> {item.type ? item.type : '-'}</p>
                                    <p className={styles[
                                        (item.plan === 'Standard' && 'standard') ||
                                        (item.plan === 'Custom' && 'custom')
                                    ]}><span>Plan</span> {item.plan || 'Basic'}</p>
                                    <p className={styles[item.isActive ? 'active' : 'inactive']}><span>Status</span> {item.isActive ? 'active' : 'inactive'}</p>
                                </div>
                                <div><p className={styles['details']} onClick={() => openDetail(item)}>Details</p></div>
                            </div>
                        ))
                        :<div> No users found </div>
                    }
                </>
                :users.map((item, i) => (
                    <div key={item._id}>
                        <div><p><span>#</span> {i + 1}</p></div>
                        <div className={styles['main-details']}>
                            <p><span>Created on</span> {`${new Date(item.createdAt)}`.substr(0, 24)}</p>
                            <p><span>Name</span> {item.firstName + " " + item.lastName}</p>
                            <p><span>Email</span> {item.email}</p>
                            <p><span>Phone</span> {item.phone}</p>
                            <p><span>Type</span> {item.type ? item.type : '-'}</p>
                            <p className={styles[
                                (item.plan === 'Standard' && 'standard') ||
                                (item.plan === 'Custom' && 'custom')
                            ]}><span>Plan</span> {item.plan || 'Basic'}</p>
                            <p className={styles[item.isActive ? 'active' : 'inactive']}><span>Status</span> {item.isActive ? 'active' : 'inactive'}</p>
                        </div>
                        <div><p className={styles['details']} onClick={() => openDetail(item)}>Details</p></div>
                    </div>
                ))
            }
        </div>
    </>)
}