import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './sidebar.module.css';

export default function Sidebar(props){
    const darkColor = '#004367';
    const lightColor = '#FFFFFF';
    const [ page, setPage ] = useState('');

    useEffect(() => {
        setPage(window.location.pathname);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname])

    return (<>
        <div className={styles['sidebar']}>
            <div className={styles['logo']}>
                <img src="../assets/images/logo.png" alt="logo" /> <span className={styles['beta']}>Beta</span>
            </div>
            <div className={styles['sidemenu']}>
                <ul>
                    <li>
                        <NavLink to="/my-qr-codes" className={styles[(page === '/my-qr-codes' || page === '/qr-code-detail') ? 'active' : '']}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none">
                                <mask id="mask0_45_39" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                    <rect width="24" height="24" fill="#D9D9D9"/>
                                </mask>
                                <g mask="url(#mask0_45_39)">
                                    <path d="M2 7V2H7V4H4V7H2ZM2 22V17H4V20H7V22H2ZM17 22V20H20V17H22V22H17ZM20 7V4H17V2H22V7H20ZM17.5 17.5H19V19H17.5V17.5ZM17.5 14.5H19V16H17.5V14.5ZM16 16H17.5V17.5H16V16ZM14.5 17.5H16V19H14.5V17.5ZM13 16H14.5V17.5H13V16ZM16 13H17.5V14.5H16V13ZM14.5 14.5H16V16H14.5V14.5ZM13 13H14.5V14.5H13V13ZM19 5V11H13V5H19ZM11 13V19H5V13H11ZM11 5V11H5V5H11ZM9.5 17.5V14.5H6.5V17.5H9.5ZM9.5 9.5V6.5H6.5V9.5H9.5ZM17.5 9.5V6.5H14.5V9.5H17.5Z" fill={(page === '/my-qr-codes' || page === '/qr-code-detail') ? lightColor : darkColor}/>
                                    <path d="M2 7V2H7V4H4V7H2ZM2 22V17H4V20H7V22H2ZM17 22V20H20V17H22V22H17ZM20 7V4H17V2H22V7H20ZM17.5 17.5H19V19H17.5V17.5ZM17.5 14.5H19V16H17.5V14.5ZM16 16H17.5V17.5H16V16ZM14.5 17.5H16V19H14.5V17.5ZM13 16H14.5V17.5H13V16ZM16 13H17.5V14.5H16V13ZM14.5 14.5H16V16H14.5V14.5ZM13 13H14.5V14.5H13V13ZM19 5V11H13V5H19ZM11 13V19H5V13H11ZM11 5V11H5V5H11ZM9.5 17.5V14.5H6.5V17.5H9.5ZM9.5 9.5V6.5H6.5V9.5H9.5ZM17.5 9.5V6.5H14.5V9.5H17.5Z" fill={(page === '/my-qr-codes' || page === '/qr-code-detail') ? lightColor : darkColor}/>
                                </g>
                            </svg>
                            <span>My QR <i>Codes</i></span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/create-qr-code" className={styles[page === '/create-qr-code' ? 'active' : '']}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none">
                                <mask id="mask0_20_139" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                    <rect width="24" height="24" fill="#D9D9D9"/>
                                </mask>
                                <g mask="url(#mask0_20_139)">
                                    <path d="M5 14V12H7V14H5ZM3 12V10H5V12H3ZM12 5V3H14V5H12ZM4.5 7.5H7.5V4.5H4.5V7.5ZM3 9V3H9V9H3ZM4.5 19.5H7.5V16.5H4.5V19.5ZM3 21V15H9V21H3ZM16.5 7.5H19.5V4.5H16.5V7.5ZM15 9V3H21V9H15ZM9 14V12H7V10H11V14H9ZM10 9V5H12V7H14V9H10ZM16 21V18H13V16H16V13H18V16H21V18H18V21H16Z" fill={page === '/create-qr-code' ? lightColor : darkColor}/>
                                </g>
                            </svg>
                            <span>Create QR <i>Code</i></span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/profile" className={styles[page === '/profile' ? 'active' : '']}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" width="24px" height="24px">
                                <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z" />
                                <path d="M20 20v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                            </svg>
                            <span>Profile</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/request-callback" className={styles[page === '/request-callback' ? 'active' : '']}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <mask id="mask0_58_312" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                    <rect width="24" height="24" fill="#D9D9D9"/>
                                </mask>
                                <g mask="url(#mask0_58_312)">
                                    <path d="M11 21V19H19V11.9C19 9.95 18.3208 8.29583 16.9625 6.9375C15.6042 5.57917 13.95 4.9 12 4.9C10.05 4.9 8.39583 5.57917 7.0375 6.9375C5.67917 8.29583 5 9.95 5 11.9V18H4C3.45 18 2.97917 17.8042 2.5875 17.4125C2.19583 17.0208 2 16.55 2 16V14C2 13.65 2.0875 13.3208 2.2625 13.0125C2.4375 12.7042 2.68333 12.4583 3 12.275L3.075 10.95C3.20833 9.81667 3.5375 8.76667 4.0625 7.8C4.5875 6.83333 5.24583 5.99167 6.0375 5.275C6.82917 4.55833 7.7375 4 8.7625 3.6C9.7875 3.2 10.8667 3 12 3C13.1333 3 14.2083 3.2 15.225 3.6C16.2417 4 17.15 4.55417 17.95 5.2625C18.75 5.97083 19.4083 6.80833 19.925 7.775C20.4417 8.74167 20.775 9.79167 20.925 10.925L21 12.225C21.3167 12.375 21.5625 12.6 21.7375 12.9C21.9125 13.2 22 13.5167 22 13.85V16.15C22 16.4833 21.9125 16.8 21.7375 17.1C21.5625 17.4 21.3167 17.625 21 17.775V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H11ZM9 14C8.71667 14 8.47917 13.9042 8.2875 13.7125C8.09583 13.5208 8 13.2833 8 13C8 12.7167 8.09583 12.4792 8.2875 12.2875C8.47917 12.0958 8.71667 12 9 12C9.28333 12 9.52083 12.0958 9.7125 12.2875C9.90417 12.4792 10 12.7167 10 13C10 13.2833 9.90417 13.5208 9.7125 13.7125C9.52083 13.9042 9.28333 14 9 14ZM15 14C14.7167 14 14.4792 13.9042 14.2875 13.7125C14.0958 13.5208 14 13.2833 14 13C14 12.7167 14.0958 12.4792 14.2875 12.2875C14.4792 12.0958 14.7167 12 15 12C15.2833 12 15.5208 12.0958 15.7125 12.2875C15.9042 12.4792 16 12.7167 16 13C16 13.2833 15.9042 13.5208 15.7125 13.7125C15.5208 13.9042 15.2833 14 15 14ZM6.025 12.45C5.90833 10.6833 6.44167 9.16667 7.625 7.9C8.80833 6.63333 10.2833 6 12.05 6C13.5333 6 14.8375 6.47083 15.9625 7.4125C17.0875 8.35417 17.7667 9.55833 18 11.025C16.4833 11.0083 15.0875 10.6 13.8125 9.8C12.5375 9 11.5583 7.91667 10.875 6.55C10.6083 7.88333 10.0458 9.07083 9.1875 10.1125C8.32917 11.1542 7.275 11.9333 6.025 12.45Z" fill={page === '/request-callback' ? lightColor : darkColor}/>
                                </g>
                            </svg>
                            <span><i>Request</i> Callback</span>
                        </NavLink>
                    </li>
                    {/* <li>
                        <NavLink to="/plans" className={styles[page === '/plans' ? 'active' : '']}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <mask id="mask0_44_668" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                <rect width="24" height="24" fill="#D9D9D9"/>
                                </mask>
                                <g mask="url(#mask0_44_668)">
                                    <path d="M22 6V18C22 18.55 21.8042 19.0208 21.4125 19.4125C21.0208 19.8042 20.55 20 20 20H4C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H20C20.55 4 21.0208 4.19583 21.4125 4.5875C21.8042 4.97917 22 5.45 22 6ZM4 8H20V6H4V8ZM4 12V18H20V12H4Z" fill={page === '/plans' ? lightColor : darkColor}/>
                                </g>
                            </svg>
                            <span>Plans</span>
                        </NavLink>
                    </li> */}
                    <li>
                        <NavLink to="/logout" className={styles[page === '/logout' ? 'active' : '']}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24px" height="24px">
                                <path d="M0 0h24v24H0z" fill="none"/>
                                <path d="M10.09 15.59L8.67 17l-5-5 5-5 1.41 1.41L6.83 11H18v2H6.83l3.26 3.29zM20 3h-8c-1.1 0-2 .9-2 2v4h2V5h8v14h-8v-4h-2v4c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/>
                            </svg>
                            <span>Logout</span>
                        </NavLink>
                    </li>
                </ul>
            </div>
            <div className={styles['plan-container']}>
                <div className={styles['plan-box']}>
                    <img src="../assets/images/plan.svg" alt="plan" />
                    <h5>Current Plan</h5>
                    <p>{props.planDetails.plan === "Loading" ? <>&nbsp;</> : props.planDetails.plan}</p>
                </div>
            </div>
        </div>
    </>)
}
