import React, { useState,  useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import api, { contactUs } from '../../../Base/Config';
import Message from '../../../Layouts/Message/Message';
import styles from './footer.module.css';

export default function Footer(){
    const captchaRef = useRef(null);
    const [ name, setName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ phone, setPhone ] = useState('');
    const [ subject, setSubject ] = useState('');
    const [ formMessage, setFormMessage ] = useState('');
    const [ messageType, setMessageType ] = useState('');
    const [ message, setMessage ] = useState('');
    const [ loading, setLoading ] = useState(false);
  
    const validateFunction = (e) => {
        e.preventDefault();
        const token = captchaRef.current.getValue();
        if(token){
            submitFunction(token);
        } else {
            setMessageType('error');
            setMessage('Please fill the captcha first');
            setTimeout(() => {
                setMessage('');
            }, 5000);
        }
    }
    
    const submitFunction = (token) => {
        setLoading(true);
        api.post(contactUs, {
            name: name,
            email: email,
            phone: phone,
            subject: subject,
            message: formMessage,
            recaptchaToken: token
        })
        .then(res => {
            if(res.data.code === 200) {
                setMessageType('success');
                emptyValues();
            } else {
                setMessageType('error');
            }
            setMessage(res.data.message);
        })
        .catch(err => {
            console.error(err);
        })
    }

    const emptyValues = () => {
        setName('');
        setEmail('');
        setPhone('');
        setSubject('');
        setFormMessage('');
    }

    return (<>
        {message && <Message type={messageType} message={message} closeMessage={() => setMessage('')} />}
        <section id={styles['footer']}>
            <div></div>
            <div></div>
        </section>
        <div className="container">
            <div className={styles['footer']}>
                <div>
                    <img src="../assets/images/logo-light.png" alt="logo" />
                    <h2>Drop your message, We will reach out to you!</h2>
                    <form onSubmit={validateFunction}>
                        <div className="row">
                            <div className="col-xs-6 col-md-6">
                                <input type="text" placeholder="Name*" value={name} onChange={(e) => setName(e.target.value)} required/>
                            </div>
                            <div className="col-xs-6 col-md-6">
                                <input type="email" placeholder="Email address*" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                            </div>
                            <div className="col-xs-6 col-md-6">
                                <input type="tel" placeholder="Mobile number*" value={phone} onChange={(e) => setPhone(e.target.value)} required/>
                            </div>
                            <div className="col-xs-6 col-md-6">
                                <input type="text" placeholder="Subject*" value={subject} onChange={(e) => setSubject(e.target.value)} required/>
                            </div>
                            <div className="col-xs-12 col-md-12">
                                <input type="text" placeholder="Your message" value={formMessage} onChange={(e) => setFormMessage(e.target.value)} required/>
                                <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY} ref={captchaRef} />
                                <br/>
                                {
                                    loading
                                    ?<button className="btn btn-light" loading>Sending</button>
                                    :<button className="btn btn-light">Send message</button>
                                }
                            </div>
                        </div>
                    </form>
                </div>
                <div>
                    <ul>
                        <li><a href="/">Homepage</a></li>
                        <li><a href="/about">About</a></li>
                        {/* <li><a href="/pricing">Pricing</a></li> */}
                        <li><a href="/blog">Blog</a></li>
                        <li><a href="/faqs">FAQs</a></li>
                        <li><a href="/contact-us">Contact</a></li>
                        <li><a href="/privacy-policy">Privacy Policy</a></li>
                    </ul>
                    <h4>Have questions? Say hello!</h4>
                    <h5>support@scanonn.com</h5>
                    <h6>Follow Us</h6>
                    {/* <a href="https://www.facebook.com" target='_blank' rel="noreferrer"><i className="fa fa-facebook"></i></a> */}
                    <a href="https://www.instagram.com/scanonn.qr/" target='_blank' rel="noreferrer"><i className="fa fa-instagram"></i></a>
                    <a href="https://www.linkedin.com/in/scan-onn-9860812b8" target='_blank' rel="noreferrer"><i className="fa fa-linkedin"></i></a>
                    <a href="https://www.youtube.com/@scanonn" target='_blank' rel="noreferrer"><i className="fa fa-youtube-play"></i></a>
                    <span>Copyright {new Date().getFullYear()} ScanOnn All right reserved.</span>
                </div>
            </div>
        </div>
    </>)
}
