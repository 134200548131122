import axios from 'axios';
import { getAdminToken } from '../Utils/Common';
export const baseurl = process.env.REACT_APP_BASEURL;

const adminapi = axios.create({
    baseURL: baseurl,
    headers: {
        'versionnumber': process.env.REACT_APP_VERSION
    },
});

// Request interceptor
adminapi.interceptors.request.use((config) => {
    const token = getAdminToken();
    if(token) {
        config.headers.token = token;
    }
    return config;
},
(error) => {
    console.error("Error while fetching token.")
    return Promise.reject(error);
});

// Response interceptor
adminapi.interceptors.response.use(
    (response) => {
        if (response.data.code === 401) {
            // Redirect to logout page
            window.location.href = '/admin/logout'; // Change this to your logout route
        }
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            // Redirect to logout page
            window.location.href = '/admin/logout'; // Change this to your logout route
        }
        return Promise.reject(error);
    }
);

export default adminapi;

// Fetch list of all registered users
export const fetchAllUsers = '/all/users';

// Delete user from admin
export const deleteUserFromAdmin = '/admin/deleteAccountUser/';

// fetch list of all categories for admin
export const getCategories = '/admin/getCategories';

// Delete category from admin
export const deleteCategory = '/admin/deleteCategory/';

// Create category from admin
export const createCategory = '/admin/createCategory';

// Update category from admin
export const updateCategory = '/admin/updateCategory';

// Get list of all QR codes created with userId and pageNumber in params
export const getQrList = '/admin/getQrListUserId/';

// This API is to resend email verification mail to the user
export const resendVerification = '/admin/resendVerification';



export const totalQrCodeCount = '/admin/totalQrCodeCount';

export const totalUserCount = '/admin/totalUserCount';

export const accountTypeCount = '/admin/accountTypeCount/';

export const accountStatusCount = '/admin/accountStatusCount/';

export const totalQrCodeTypeCount = '/admin/totalQrCodeTypeCount/';

export const totalScannedQrCount = '/admin/totalScannedQrCount';

export const updateStatus = '/admin/updateStatus';

// Fetch list of top categories used
export const getTopCategories = '/admin/getTopCategories';

// Fetch list of top users
export const getTopUsers = '/admin/getTopUsers';

// Create new service with plan and price details
export const createService = '/admin/create-service';

// Update service on the basis of key
export const updateService = '/admin/update-service';

// Fetch list of all services or by plan
export const fetchServices = '/admin/fetch-services/All';

// Delete service
export const deleteService = '/admin/delete-service/';

// Filter API
export const adminFilter = '/admin/userFiler/?';
