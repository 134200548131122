export const downloadQr = (imageUrl) => {
    // // Create a temporary link element
    // const link = document.createElement('a');
    // link.href = imageUrl;

    // // Set the download attribute with the desired file name
    // link.download = 'qr.png';

    // // Append the link to the document body
    // document.body.appendChild(link);

    // // Programmatically click the link to trigger the download
    // link.click();

    // // Remove the link from the document
    // document.body.removeChild(link);

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    const img = new Image();
    img.crossOrigin = 'Anonymous'; // Ensure this is set for CORS
    img.src = imageUrl;

    img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0);

        // Convert canvas to data URL and trigger download
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = imageUrl.split('/')[3]; // Set the name for the downloaded file
        link.click();
    };

    img.onerror = () => {
        console.error('Image failed to load');
    };
};