import React, { useState, useEffect } from 'react';
import tokenapi, { getPlan } from '../../../Base/TokenConfig';
import styles from '../profile.module.css';

export default function Plan() {
    const [planDetails, setPlanDetails] = useState('');

    useEffect(() => {
        fetchPlanDetails();
    }, [])

    const fetchPlanDetails = () => {
        tokenapi.get(getPlan)
        .then(res => {
            if(res.data.code === 200) {
                setPlanDetails(res.data.result);
            }
        })
        .catch(err => {
            console.error(err);
        })
    }

    return (<>
        <h2 className={styles['profile-card-heading']}>Plan details</h2>
        <div className={styles['profile-card']}>
            <div className={styles['profile-card-details']}>
                {
                    planDetails
                    ?<>
                        <p>
                            <span>Plan</span>
                            {planDetails.plan}
                        </p>
                        <p>
                            <span>Plan period</span>
                            {planDetails.planPeriod}
                        </p>
                        <p>
                            <span>Subscription type</span>
                            {planDetails.subscriptionType}
                        </p>
                        <p>
                            <span>Start date</span>
                            {`${new Date(planDetails.startDate)}`.substr(4, 11)}
                        </p>
                        <p>
                            <span>End date</span>
                            {`${new Date(planDetails.endDate)}`.substr(4, 11)}
                        </p>
                    </>
                    :<p>
                        <span>Plan</span>
                        Basic
                    </p>
                }
            </div>
        </div>
    </>)
}