import React, { useEffect, useState } from 'react';
import countryCodes from '../../../../Common/countryCodes.json';
import styles from '../../create-qr-code.module.css';

export default function WhatsApp(props) {
    const [title, setTitle] = useState('');
    const [countryCode, setCountryCode] = useState('+91');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        if(sessionStorage.getItem('qr-content')) {
            const qrContent = JSON.parse(sessionStorage.getItem('qr-content'));
            setTitle(qrContent.title);
            setCountryCode(qrContent.countryCode);
            setPhone(qrContent.phone);
            setMessage(qrContent.message);
        }
    }, [])

    const createFunction = (e) => {
        e.preventDefault();
        sessionStorage.setItem('qr-content', JSON.stringify({ title, countryCode, phone, message }));
        props.stepChangeFunction(3);
    }

    return (<>
        <form onSubmit={createFunction}>
            <div className={styles['qr-title']}>
                <label>Title*</label>
                <input type="text" placeholder="Enter QR title here" value={title} onChange={e => setTitle(e.target.value)} required/>
            </div>
            <div className={styles['section-heading']}>
                <hr/>
                <h3>Details</h3>
            </div>
            <div className={styles['create-qr-fields']}>
                <div>
                    <label>Phone*</label>
                    <div className='phone-input'>
                        <select value={countryCode} onChange={(e) => setCountryCode(e.target.value)} required>
                            {countryCodes.map((item, i) => (
                                <option value={item.dial_code} key={i}>{item.dial_code}</option>
                            ))}
                        </select>
                        <input type="text" placeholder="Enter phone number" value={phone} onChange={(e) => setPhone(e.target.value)} required/>
                    </div>
                </div>
            </div>
            <div className={styles['create-qr-fields']}>
                <div>
                    <label>Message*</label>
                    <textarea placeholder="Enter message" rows="5" className="form-control" value={message} onChange={e => setMessage(e.target.value)} required></textarea>
                </div>
            </div>
            <button type="submit" className="btn btn-primary">Next</button>
        </form>
    </>)
}