import React from 'react';
import { Helmet } from 'react-helmet';
import CreateManageQR from '../Components/CreateManageQR/CreateManageQR';
import styles from './product.module.css';

export default function Product(){
    return (<>
        <Helmet>
            <title>Custom QR Code Generator - Create, Share, and Engage</title>
            <meta name="description" content="Easily generate personalized QR codes for your business, events, or personal projects with our user-friendly QR Code Generator.Unique designs, colors, and logos" />
        </Helmet>
        <section id={styles['banner']}>
            <div className="container">
                <div className={styles["banner"]}>
                    <div>
                        <h1>Simplify, Scan, and Share!”</h1>
                    </div>
                    <div>
                        <img src="../assets/images/product.png" alt="product" width="100%" />
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className='container'>
                <div className={styles['qr-code-generation']}>
                    <div>
                        <img src="../assets/images/qr-code-generation.png" alt="qr-code" width="100%" />
                    </div>
                    <div>
                        <h2>QR Code Generator</h2>
                        <p>Our user-friendly QR code generator allows you to create custom codes for your business, events, or personal use in seconds. Whether you're listing products for an e-commerce store, showcasing real estate properties, designing a restaurant menu, or implementing an online ordering system, our platform has you covered.</p>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className='container'>
                <div className={styles['real-time-analytics']}>
                    <div>
                        <h2>Real-Time Analytics</h2>
                        <p>Gain valuable insights into your QR code performance with real-time analytics. Track engagement, monitor performance, and make informed decisions based on data.</p>
                    </div>
                    <div>
                        <img src="../assets/images/realtime-analytics.png" alt="analytics" width="100%" />
                    </div>
                </div>
            </div>
        </section>
        <CreateManageQR />
        <section>
            <div className='container'>
                <div className={styles['customization-options']}>
                    <div>
                        <img src="../assets/images/customized-image.png" alt="customized" width="100%" />
                    </div>
                    <div>
                        <h2>Customization Options</h2>
                        <p>Customize your templates and themes to suit your unique requirements. Our team can assist with quick modifications or long-term development projects to enhance your chosen template.</p>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className='container'>
                <div className={styles['integration']}>
                    <div>
                        <h2>Integration With Other Software Platforms</h2>
                        <p>Seamlessly integrate our platform with other software solutions, such as marketing automation software and CRM systems, to streamline your workflow and enhance productivity.</p>
                    </div>
                    <div>
                        <img src="../assets/images/software.png" alt="software" width="100%" />
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className='container'>
                <div className={styles['customization-options']}>
                    <div>
                        <img src="../assets/images/favicon.png" alt="favicon" width="100%" />
                    </div>
                    <div>
                        <h2>Template and Theme Options</h2>
                        <p>Choose from a range of predefined templates and themes for a semi or fully white-label solution tailored to your business. With our platform, you can have your portal up and running in just 30-40 minutes, complete with real-time payment gateway integration for seamless transactions.</p>
                    </div>
                </div>
            </div>
        </section>
    </>)
}
