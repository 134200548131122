import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import tokenapi, { getQrList } from '../../Base/TokenConfig';
import { setQrDetail, removeQrDetail } from '../../Utils/Common';
import { downloadQr } from '../../Utils/downloadQr';
import Navbar from '../Components/Navbar/Navbar';
import Modal from '../../Layouts/Modal/Modal';
import Analytics from './Components/Analytics';
import VcardDetails from './Components/VcardDetails';
import UrlDetails from './Components/UrlDetails';
import WhatsappDetails from './Components/WhatsappDetails';
import TextDetails from './Components/TextDetails';
import MediaDetails from './Components/MediaDetails';
import PDFDetails from './Components/PDFDetails';
import LocationDetails from './Components/LocationDetails';
import UPIDetails from './Components/UPIDetails';
import styles from './my-qr-codes.module.css';

export default function MyQRCodes(){
    const navigate = useNavigate();
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState('');
    const [qrArray, setQrArray] = useState([]);
    const [singleDetail, setSingleDetail] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [qrModal, setQrModal] = useState(false);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        removeQrDetail();
        fetchFunction(pageNumber);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchFunction = (number) => {
        tokenapi.get(getQrList + number)
        .then(res => {
            setLoader(false);
            if(res.data.code === 200){
                setQrArray(res.data.records);
                setPageNumber(parseInt(res.data.pageNumber));
                setTotalPages(Math.ceil(res.data.totalRecords/res.data.pageSize));
            }
        })
        .catch(err => {
            setLoader(false);
            console.error(err);
        })
    }

    const openEditModal = (object) => {
        console.log(object);
        setSingleDetail(object);
        setEditModal(true);
    }

    const openDetail = (detail) => {
        setQrDetail(detail);
        navigate('/qr-code-detail');
    }

    return (<>
        <Helmet>
            <title>ScanOnn</title>
            <meta name="description" content="ScanOnn | QR code generation and tracking solutions" />
        </Helmet>
        {editModal && <Modal closeModal={() => setEditModal(false)}>
            <div className={styles["modal-box"]}>
                <div className={styles['modal-heading']}>Details <img src="./assets/images/times.png" onClick={() => setEditModal(false)} alt="times" /></div>
                {singleDetail.categoryName === 'Visiting Card' && <VcardDetails singleDetail={singleDetail} fetchFunction={fetchFunction} closeFunction={() => setEditModal(false)} />}
                {singleDetail.categoryName === 'URL' && <UrlDetails singleDetail={singleDetail} fetchFunction={fetchFunction} closeFunction={() => setEditModal(false)} />}
                {singleDetail.categoryName === 'WhatsApp' && <WhatsappDetails singleDetail={singleDetail} fetchFunction={fetchFunction} closeFunction={() => setEditModal(false)} />}
                {singleDetail.categoryName === 'Text' && <TextDetails singleDetail={singleDetail} fetchFunction={fetchFunction} closeFunction={() => setEditModal(false)} />}
                {singleDetail.categoryName === 'Media' && <MediaDetails singleDetail={singleDetail} fetchFunction={fetchFunction} closeFunction={() => setEditModal(false)} />}
                {singleDetail.categoryName === 'PDF' && <PDFDetails singleDetail={singleDetail} fetchFunction={fetchFunction} closeFunction={() => setEditModal(false)} />}
                {singleDetail.categoryName === 'Location' && <LocationDetails singleDetail={singleDetail} fetchFunction={fetchFunction} closeFunction={() => setEditModal(false)} />}
                {singleDetail.categoryName === 'UPI' && <UPIDetails singleDetail={singleDetail} fetchFunction={fetchFunction} closeFunction={() => setEditModal(false)} />}
            </div>
        </Modal>}
        {qrModal && <Modal closeModal={() => setQrModal(false)}>
            <div className={styles["qr-modal-box"]}>
                <img src={singleDetail.s3Url} alt="single-qr" />
                <h4>Scan for details</h4>
                <p><span>Title:</span> {singleDetail.title}</p>
                <p><span>Category:</span> {singleDetail.categoryName}</p>
            </div>
        </Modal>}
        <Navbar heading='My QR Codes' subheading='View and Manage Your QR Codes' />
        <div className="dashboard-content">
            {
                loader
                ?<>
                    <div className={styles['loader-box']}></div>
                    <div className={styles['loader-box']}></div>
                    <div className={styles['loader-box']}></div>
                    <div className={styles['loader-box']}></div>
                </>
                :<>
                    {
                        qrArray.length > 0
                        ?<>
                            <Analytics />
                            {qrArray.map((item, i) => (
                                <div className={styles['list-card']} key={i}>
                                    <div className={styles['list-card-content']}>
                                        <div className={styles['basic-info']}>
                                            <div>
                                                <p className={styles['qr-title']}>{item.title}</p>
                                                <p className={styles['qr-category']}>{item.categoryName}</p>
                                                <p className={styles['qr-info']}><span>{item.qrType === 'dynamic' ? 'Dynamic' : 'Static'}</span></p>
                                            </div>
                                            <div className={styles['qr-image']}>
                                                <img src={item.s3Url} alt="my-qr" onClick={() => {setSingleDetail(item); setQrModal(true);}} />
                                            </div>
                                        </div>
                                        <div className={styles['other-info']}>
                                            <div>
                                                {item.encWebUrl && <p className={styles['qr-info']}><i className="fa fa-globe"></i> {item.encWebUrl}</p>}
                                                <p className={styles['qr-info']}><i className="fa fa-calendar"></i> <span>Created:</span> {`${new Date(item.createdAt)}`.substr(0,15)}</p>
                                                {item.updatedAt && <p className={styles['qr-info']}><i className="fa fa-pencil"></i> <span>Modified:</span> {`${new Date(item.updatedAt)}`.substr(0,15)}</p>}
                                            </div>
                                            <p className={styles['qr-scans']}>{item.totalScans ? item.totalScans : '0'}<span>{item.totalScans === 1 ? 'Scan' : 'Scans'}</span></p>
                                            <div className={styles['list-action']}>
                                                <p className={styles['detail-button']} onClick={() => openDetail(item)}>Details</p>
                                                {
                                                    item.qrType === 'dynamic'
                                                    ?<p className={styles['edit-button']} onClick={() => openEditModal(item)}><span className="fa fa-edit"></span></p>
                                                    :<p className={styles['download-button']} onClick={() => downloadQr(item.s3Url)}><span className="fa fa-download"></span></p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {totalPages !== 1 && <div className={styles['pagination']}>
                                {
                                    pageNumber === 1
                                    ?<span className={styles['disabled']}><i className="fa fa-angle-left"></i></span>
                                    :<span onClick={() => fetchFunction(pageNumber - 1)}><i className="fa fa-angle-left"></i></span>
                                }
                                {Array(totalPages).fill(null).map((_, i) => (
                                    <span className={styles[(pageNumber === i + 1) ? 'active' : '']} onClick={() => fetchFunction(i + 1)} key={i}>{i + 1}</span>
                                ))}
                                {
                                    pageNumber === totalPages
                                    ?<span className={styles['disabled']}><i className="fa fa-angle-right"></i></span>
                                    :<span onClick={() => fetchFunction(pageNumber + 1)}><i className="fa fa-angle-right"></i></span>
                                }
                            </div>}
                        </>
                        :<p className={styles['no-qr-created']}> No QR created </p>
                    }
                </>
            }
        </div>
    </>)
}
