import React, { useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';
import { setAdminToken } from '../../Utils/Common';
import api, { adminLogin } from '../../Base/Config';
import Message from '../../Layouts/Message/Message';
import styles from './login.module.css';

export default function Login(){
    const navigate = useNavigate();
    const captchaRef = useRef(null);
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ showPassword, setShowPassword ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ message, setMessage ] = useState('');
    const [ messageType, setMessageType ] = useState('');
  
    const submitFunction = (e) => {
        e.preventDefault();
        const token = captchaRef.current.getValue();
        if(token) {
            loginFunction(token);
        } else {
            setMessageType('error');
            setMessage('Please fill the captcha first');
            setTimeout(() => {
                setMessage('');
            }, 5000);
        }
    }

    const loginFunction = (token) => {
        setLoading(true);
        api.post(adminLogin, {
            email: email,
            password: password,
            recaptchaToken: token
        })
        .then(res => {
            setLoading(false);
            if(res.data.code === 200){
                setAdminToken(res.data.token);
                setMessageType('success');
                setMessage('Authentication successful');
                setTimeout(() => {
                    navigate('/admin/dashboard');
                }, 1000)
            } else {
                captchaRef.current.reset();
                setMessageType('error');
                setMessage(res.data.message);
            }
        })
        .catch(err => {
            console.error(err);
            captchaRef.current.reset();
        })
    }

    return (<>
        {message && <Message type={messageType} message={message} closeMessage={() => setMessage('')} />}
        <div className={styles['login-page']}>
            <div className={styles['login-content']}></div>
            <div className={styles['login-form']}>
                <form onSubmit={submitFunction}>
                    <h4>Admin login</h4>
                    <label>EMAIL ADDRESS</label>
                    <input type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                    <label>PASSWORD</label>
                    <div className={styles['hide-show']}>
                        <input type={showPassword ? 'text' : 'password'} placeholder="Enter password" autoComplete="new-password" value={password} onChange={(e) => setPassword(e.target.value)} required/>
                        <div onClick={() => setShowPassword(!showPassword)}><span className={`fa fa-${showPassword ? 'eye' : 'eye-slash'}`}></span></div>
                    </div>
                    <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY} ref={captchaRef} />
                    <br/>
                    <button className="button black-button" disabled={loading}>{loading ? 'Wait ...' : 'Submit'}</button>
                </form>
            </div>
        </div>
    </>)
}
