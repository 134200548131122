import React, { useEffect, useState } from 'react';
import tokenapi, { uploadFile } from '../../../../Base/TokenConfig';
import Message from '../../../../Layouts/Message/Message';
import styles from '../../create-qr-code.module.css';

export default function PDF(props) {
    const [title, setTitle] = useState('');
    const [pdfUrl, setPdfUrl] = useState('');
    const [messageType, setMessageType] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        if(sessionStorage.getItem('qr-content')) {
            const qrContent = JSON.parse(sessionStorage.getItem('qr-content'));
            setTitle(qrContent.title);
            setPdfUrl(qrContent.pdfUrl);
        }
    }, [])

    const createFunction = (e) => {
        e.preventDefault();
        sessionStorage.setItem('qr-content', JSON.stringify({ title, pdfUrl }));
        props.stepChangeFunction(3);
    }

    const uploadFunction = (file) => {
        const formData = new FormData();
        formData.append("file", file);
        tokenapi.post(uploadFile, formData)
        .then(res => {
            setMessage(res.data.message);
            if(res.data.code === 200) {
                setMessageType('success');
                setPdfUrl(res.data.result);
            } else {
                setMessageType('error');
            }
        })
        .catch(err => {
            console.error(err);
            setMessageType('error');
            setMessage('Something was wrong');
        })
    }

    return (<>
        {message && <Message type={messageType} message={message} closeMessage={() => setMessage('')} />}
        <form onSubmit={createFunction}>
            <div className={styles['qr-title']}>
                <label>Title*</label>
                <input type="text" placeholder="Enter QR title here" value={title} onChange={e => setTitle(e.target.value)} required/>
            </div>
            <div className={styles['section-heading']}>
                <hr/>
                <h3>Details</h3>
            </div>
            <div className={styles['create-qr-fields']}>
                <div>
                    <label>Upload file*</label>
                    {
                        pdfUrl
                        ?<input type="text" value="Image uploaded" disabled/>
                        :<input type="file" accept=".pdf" onChange={e => uploadFunction(e.target.files[0])} />
                    }
                </div>
            </div>
            <button type="submit" className="btn btn-primary">Next</button>
        </form>
    </>)
}