import React, { useEffect, useState } from 'react';
import tokenapi, { uploadImg } from '../../../../Base/TokenConfig';
import { addHttps } from '../../../../Utils/addHttps';
import Message from '../../../../Layouts/Message/Message';
import styles from '../../create-qr-code.module.css';

export default function VisitingCard(props) {
    const [title, setTitle] = useState('');
    const [website, setWebsite] = useState('');
    const [name, setName] = useState('');
    const [about, setAbout] = useState('');
    const [organization, setOrganization] = useState('');
    const [designation, setDesignation] = useState('');
    const [phoneWork, setPhoneWork] = useState('');
    const [phonePrivate, setPhonePrivate] = useState('');
    const [landline, setLandline] = useState('');
    const [fax, setFax] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [googleMap, setGoogleMap] = useState('');
    const [fileUploading, setFileUploading] = useState(false);
    const [photo, setPhoto] = useState('');
    const [facebook, setFacebook] = useState('');
    const [twitter, setTwitter] = useState('');
    const [linkedin, setLinkedin] = useState('');
    const [instagram, setInstagram] = useState('');
    const [youtube, setYoutube] = useState('');
    const [messageType, setMessageType] = useState('');
    const [message, setMessage] = useState('');

    const uploadFunction = (file) => {
        setFileUploading(true);
        const formData = new FormData();
        formData.append("file", file);
        tokenapi.post(uploadImg, formData)
        .then(res => {
            setFileUploading(false);
            if(res.data.code === 200) {
                setPhoto(res.data.result);
            } else {
                setMessageType('error');
                setMessage(res.data.message);
            }
        })
        .catch(err => {
            setFileUploading(false);
            console.error(err);
        })
    }

    useEffect(() => {
        if(sessionStorage.getItem('qr-content')) {
            const qrContent = JSON.parse(sessionStorage.getItem('qr-content'));
            setTitle(qrContent.title);
            setWebsite(qrContent.website);
            setName(qrContent.name);
            setAbout(qrContent.about);
            setOrganization(qrContent.organization);
            setDesignation(qrContent.designation);
            setPhoneWork(qrContent.phoneWork);
            setPhonePrivate(qrContent.phonePrivate);
            setLandline(qrContent.landline);
            setFax(qrContent.fax);
            setEmail(qrContent.email);
            setAddress(qrContent.address);
            setGoogleMap(qrContent.googleMap);
            setPhoto(qrContent.photo);
            setFacebook(qrContent.socialMedia?.facebook);
            setTwitter(qrContent.socialMedia?.twitter);
            setLinkedin(qrContent.socialMedia?.linkedin);
            setInstagram(qrContent.socialMedia?.instagram);
            setYoutube(qrContent.socialMedia?.youtube);
        }
    }, [])

    const createFunction = (e) => {
        e.preventDefault();
        sessionStorage.setItem('qr-content', JSON.stringify({
            title: title,
            name: name,
            about: about,
            organization: organization,
            designation: designation,
            phoneWork: phoneWork,
            phonePrivate: phonePrivate,
            landline: landline,
            fax: fax,
            email: email,
            website: addHttps(website),
            address: address,
            googleMap: addHttps(googleMap),
            photo: photo,
            socialMedia: {
                facebook: addHttps(facebook),
                twitter: addHttps(twitter),
                linkedin: addHttps(linkedin),
                instagram: addHttps(instagram),
                youtube: addHttps(youtube)
            }
        }));
        props.stepChangeFunction(3);
    }

    return (<>
        {message && <Message type={messageType} message={message} closeMessage={() => setMessage('')} />}
        <form onSubmit={createFunction}>
            <div className={styles['qr-title']}>
                <label>Title*</label>
                <input type="text" placeholder="Enter QR title here" value={title} onChange={e => setTitle(e.target.value)} required/>
            </div>
            <div className={styles['section-heading']}>
                <hr/>
                <h3>Personal information</h3>
            </div>
            <div className={styles['create-qr-fields']}>
                <div>
                    <label>Name*</label>
                    <input type="text" placeholder="Enter name" value={name} onChange={e => setName(e.target.value)} required/>
                </div>
                <div>
                    <label>About*</label>
                    <input type="text" placeholder="Enter about" value={about} onChange={e => setAbout(e.target.value)} required/>
                </div>
                <div>
                    <label>Organization</label>
                    <input type="text" placeholder="Enter organization" value={organization} onChange={e => setOrganization(e.target.value)} />
                </div>
                <div>
                    <label>Designation</label>
                    <input type="text" placeholder="Enter designation" value={designation} onChange={e => setDesignation(e.target.value)} />
                </div>
                <div>
                    <label>Photograph</label>
                    {
                        fileUploading
                        ?<div className={styles['uploading-file']}><i className="fa fa-circle-o-notch fa-spin"></i> Uploading file</div>
                        :<>
                            {
                                photo
                                ?<input type="text" defaultValue="File uploaded" disabled/>
                                :<input type="file" accept=".png,.jpg,.jpeg" onChange={e => uploadFunction(e.target.files[0])} />
                            }
                        </>
                    }
                </div>
                <div>
                    <label>Website URL</label>
                    <input type="text" placeholder="Enter website url" value={website} onChange={e => setWebsite(e.target.value)} />
                </div>
            </div>
            <div className={styles['section-heading']}>
                <hr/>
                <h3>Contact information</h3>
            </div>
            <div className={styles['create-qr-fields']}>
                <div>
                    <label>Phone (Work)</label>
                    <input type="text" placeholder="Enter phone" value={phoneWork} onChange={e => setPhoneWork(e.target.value)} />
                </div>
                <div>
                    <label>Phone (Private)</label>
                    <input type="text" placeholder="Enter phone" value={phonePrivate} onChange={e => setPhonePrivate(e.target.value)} />
                </div>
                <div>
                    <label>Landline</label>
                    <input type="text" placeholder="Enter landline" value={landline} onChange={e => setLandline(e.target.value)} />
                </div>
                <div>
                    <label>Fax</label>
                    <input type="text" placeholder="Enter fax" value={fax} onChange={e => setFax(e.target.value)} />
                </div>
                <div>
                    <label>Email</label>
                    <input type="email" placeholder="Enter email" value={email} onChange={e => setEmail(e.target.value)} />
                </div>
                <div>
                    <label>Address</label>
                    <input type="text" placeholder="Enter address" value={address} onChange={e => setAddress(e.target.value)} />
                </div>
                <div>
                    <label>Google Map</label>
                    <input type="text" placeholder="Enter address" value={googleMap} onChange={e => setGoogleMap(e.target.value)} />
                </div>
            </div>
            <div className={styles['section-heading']}>
                <hr/>
                <h3>Social media</h3>
            </div>
            <div className={styles['create-qr-fields']}>
                <div>
                    <label>Facebook</label>
                    <input type="text" placeholder="Enter facebook link" value={facebook} onChange={e => setFacebook(e.target.value)} />
                </div>
                <div>
                    <label>Twitter</label>
                    <input type="text" placeholder="Enter twitter link" value={twitter} onChange={e => setTwitter(e.target.value)} />
                </div>
                <div>
                    <label>Linkedin</label>
                    <input type="text" placeholder="Enter linkedin link" value={linkedin} onChange={e => setLinkedin(e.target.value)} />
                </div>
                <div>
                    <label>Instagram</label>
                    <input type="text" placeholder="Enter instagram link" value={instagram} onChange={e => setInstagram(e.target.value)} />
                </div>
                <div>
                    <label>Youtube</label>
                    <input type="text" placeholder="Enter youtube link" value={youtube} onChange={e => setYoutube(e.target.value)} />
                </div>
            </div>
            <button type="submit" className="btn btn-primary">Next</button>
        </form>
    </>)
}