import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import adminapi, { getQrList, deleteUserFromAdmin, resendVerification, updateStatus } from '../../Base/AdminConfig';
import { getUserDetail, setUserDetail } from '../../Utils/Common';
import Message from '../../Layouts/Message/Message';
import Modal from '../../Layouts/Modal/Modal';
import styles from './users.module.css';

export default function UserDetail(){
    const navigate = useNavigate();
    const userDetail = getUserDetail();
    const [qrDetail, setQrDetail] = useState([]);
    const [resendLoading, setResendLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState('');
    const [message, setMessage] = useState('');
    const [deleteUserModal, setDeleteUserModal] = useState(false);
    const [selectedTab, setSelectedTab] = useState('user-details');
    const [selectedQr, setSelectedQr] = useState('');

    useEffect(() => {
        qrCreated(userDetail.userId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const qrCreated = (userId) => {
        // setQrLoading(true);
        adminapi.get(getQrList + userId)
        .then(res => {
            // setQrLoading(false);
            if(res.data.code === 200){
                setQrDetail(res.data.records);
                // setMessageType('success');
            } else {
                // setMessageType('error');
            }
            // setMessage(res.data.message);
        })
        .catch(err => {
            // setQrLoading(false);
            console.error(err);
        })
    }

    const deleteUser = () => {
        setLoading(true);
        adminapi.delete(deleteUserFromAdmin + userDetail.userId)
        .then(res => {
            setLoading(false);
            if(res.data.code === 200){
                setMessageType('success');
                navigate('/admin/users');
            } else {
                setMessageType('error');
                setTimeout(() => {
                }, 1000)
            }
            setMessage(res.data.message);
        })
        .catch(err => {
            setLoading(false);
            console.error(err);
        })
    }

    const resendVerificationFunction = () => {
        setResendLoading(true);
        adminapi.post(resendVerification, {
            email: userDetail.email
        })
        .then(res => {
            setResendLoading(false);
            if(res.data.code === 200){
                setMessageType('success');
            } else {
                setMessageType('error');
                setTimeout(() => {
                }, 1000)
            }
            setMessage(res.data.message);
        })
        .catch(err => {
            setResendLoading(false);
            console.error(err);
        })
    }

    const updateStatusFunction = (status) => {
        setResendLoading(true);
        adminapi.post(updateStatus, {
            userId: userDetail.userId,
            isActive: status
        })
        .then(res => {
            setResendLoading(false);
            if(res.data.code === 200){
                setMessageType('success');
                userDetail.isActive = status;
                setUserDetail(userDetail);
                setDeleteUserModal(false);
            } else {
                setMessageType('error');
                setTimeout(() => {
                }, 1000)
            }
            setMessage(res.data.message);
        })
        .catch(err => {
            setResendLoading(false);
            console.error(err);
        })
    }

    function camelCaseToPhrase(camelCase) {
        return camelCase
        .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before capital letters
        .replace(/([A-Z]+)/g, ' $1')          // Add space before uppercase sequences
        .trim()                               // Remove any leading/trailing spaces
        .toLowerCase()                        // Convert entire string to lowercase
        .replace(/^\w/, (c) => c.toUpperCase()); // Capitalize the first letter
    }

    const dummyFunc = (obj) => {
        // Convert object to array with support for nested objects
        const flattenObject = (obj) => {
            return Object.entries(obj).flatMap(([key, value]) => {
                if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                    return Object.entries(value).map(([nestedKey, nestedValue]) => ({
                        key: `${key}.${nestedKey}`,  // Create a key for the nested property
                        value: nestedValue
                    }));
                }
                return [{ key, value }];
            });
        };

        const arr = flattenObject(obj);
        setSelectedQr(arr);
    }

    return (<>
        {message && <Message type={messageType} message={message} closeMessage={() => setMessage('')} />}
        {deleteUserModal && <Modal closeModal={() => setDeleteUserModal(false)}>
            <div className={styles['delete-modal']}>
                <div className={styles['delete-modal-heading']}>Are you sure you want to delete this user?</div>
                <p className={styles['warning-text']}>This action cannot be undone. Instead of deleting, consider changing the user's status to inactive.</p>
                {/* User: [Insert user details here] */}
                <div className={styles['delete-modal-buttons']}>
                    <button className="btn btn-primary" onClick={() => updateStatusFunction(!userDetail.isActive)}>Change Status</button>
                    {
                        loading
                        ?<button className="btn btn-danger" disabled>Deleting</button>
                        :<button className="btn btn-danger" onClick={() => deleteUser()}>Delete User</button>
                    }
                </div>
                <p className={styles['cancel-text']} onClick={() => setDeleteUserModal(false)}>No, changed my mind</p>
            </div>
        </Modal>}
        {selectedQr && <Modal closeModal={() => setSelectedQr('')}>
            <div className={styles['qr-details']}>
                <p>QR detail</p>
                {selectedQr.map((item, i) => (
                    <p key={i}><span>{camelCaseToPhrase(item.key)}:</span> {item.value}</p>
                ))}
            </div>
        </Modal>}
        <h1>Users <i className="fa fa-angle-right"></i> detail</h1>
        <div className={styles['user-detail-tabs']}>
            <span className={styles[selectedTab === 'user-details' && 'selected']} onClick={() => setSelectedTab('user-details')}>User details</span>
            <span className={styles[selectedTab === 'qr-created' && 'selected']} onClick={() => setSelectedTab('qr-created')}>QR created</span>
            <span className={styles[selectedTab === 'settings' && 'selected']} onClick={() => setSelectedTab('settings')}>Settings</span>
        </div>
        {selectedTab === 'user-details' && <>
            <h6 className={styles['card-heading']}>User Details</h6>
            <div className={styles['user-detail']}>
                <p><span>User id</span> {userDetail.userId}</p>
                <p><span>Customer id</span> {userDetail.customerId}</p>
                <p><span>First name</span> {userDetail.firstName}</p>
                <p><span>Last name</span> {userDetail.lastName}</p>
                <p><span>Email</span> {userDetail.email}</p>
                <p><span>Phone</span> {userDetail.phone ? userDetail.phone : 'Not added'}</p>
                <p><span>Created at</span> {`${new Date(userDetail.createdAt)}`.substr(0, 24)}</p>
                <p><span>Status</span> {userDetail.isActive ? 'Active' : 'Inactive' }</p>
                <p><span>Password</span> {userDetail.password ? 'True' : 'False'}</p>
                <p><span>Role</span> {userDetail.role}</p>
            </div>
        </>}
        {selectedTab === 'qr-created' && <>
            <h6 className={styles['card-heading']}>QR created {qrDetail.length > 0 ? `(${qrDetail.length})` : ''}</h6>
            {
                qrDetail.length > 0
                ?<div className={styles['qr-detail-cards']}>
                    {qrDetail.map((item, i) => (
                        <div className={styles['qr-detail']} key={i}>
                            <p><span>Title:</span> {item.title}</p>
                            <p><span>Category name:</span> {item.categoryName}</p>
                            <p><span>Total scans:</span> {item.totalScans ? item.totalScans : 0}</p>
                            <p><span>Created at:</span> {`${new Date(item.createdAt)}`.substr(0, 24)}</p>
                            <span onClick={() => dummyFunc(item)}>Details</span>
                        </div>
                    ))}
                </div>
                :<div className={styles['no-records']}>
                    <span>no QR created</span>
                </div>
            }
        </>}
        {selectedTab === 'settings' && <>
            <h6 className={styles['card-heading']}>Settings</h6>
            <div className={styles['settings-options']}>
                <div className={styles['settings-box']}>
                    <p>Delete account</p>
                    <div>
                        <button className="btn btn-danger" onClick={() => setDeleteUserModal(true)}>Delete</button>
                    </div>
                </div>
                <div className={styles['settings-box']}>
                    <p>Change status</p>
                    <div className={styles['radio-inputs']}>
                        <span><input type="radio" name="status" onClick={() => updateStatusFunction(true)} checked={userDetail.isActive === true} /> Active</span>
                        <span><input type="radio" name="status" onClick={() => updateStatusFunction(false)} checked={userDetail.isActive === false} /> Inactive</span>
                    </div>
                </div>
                {!userDetail.isActive && <div className={styles['settings-box']}>
                    <p>Verification mail</p>
                    <div>
                        {
                            resendLoading
                            ?<button className="btn btn-primary mt-3 mb-2 mr-3" disabled>Sending ...</button>
                            :<button className="btn btn-primary mt-3 mb-2" onClick={() => resendVerificationFunction()}>Resend</button>
                        }
                    </div>
                </div>}
                <div className={styles['settings-box']}>
                    <p>Change plan</p>
                    <div style={{color:'#AAA'}}>
                        Coming soon
                    </div>
                </div>
            </div>
        </>}
    </>)
}