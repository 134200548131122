import React, { useEffect, useState } from 'react';
import tokenapi, { fetchAllServices, upgradePlan } from '../../../Base/TokenConfig';
import Message from '../../../Layouts/Message/Message';
import Modal from '../../../Layouts/Modal/Modal';
import styles from '../plans.module.css';

export default function MorePlans(props) {
    const [basicServices, setBasicServices] = useState([]);
    const [standardServices, setStandardServices] = useState([]);
    const [selectedService, setSelectedService] = useState([]);
    const [customAmount, setCustomAmount] = useState(0);
    const [selectedMonths, setSelectedMonths] = useState(1);
    const [activateBasic, setActivateBasic] = useState(false);
    const [modal, setModal] = useState(false);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [amount, setAmount] = useState(0);
    const [gstAmount, setGstAmount] = useState(0);
    const [amountWithGst, setAmountWithGst] = useState(0);

    useEffect(() => {
        fetchAllServicesFunction('Basic');
        fetchAllServicesFunction('Standard');
    }, [])

    const fetchAllServicesFunction = (x) => {
        tokenapi.get(fetchAllServices + x)
        .then(res => {
            // setLoader(false);
            if(res.data.code === 200) {
                if(x === 'Basic') {
                    setBasicServices(res.data.result);
                } else if(x === 'Standard') {
                    setStandardServices(res.data.result);
                }
            }
        })
        .catch(err => {
            // setLoader(false);
            console.error(err);
        })
    }

    const addFeature = (item) => {
        if(selectedService.filter(x => x.key === item.key).length > 0) {
            // Remove item if already selected
            setSelectedService(selectedService.filter(x => x.key !== item.key));
            calculateAmountFunction(customAmount - item.price, selectedMonths);
        } else {
            // Add item to selected items
            setSelectedService([...selectedService, item]);
            calculateAmountFunction(customAmount + item.price, selectedMonths);
        }
    }

    const calculateAmountFunction = (price, months) => {
        setCustomAmount(price);
        const amount = price * months;
        const gstAmount = Number((amount * 0.18).toLocaleString());
        const amountWithGst = amount + gstAmount;
        setAmount(amount);
        setGstAmount(gstAmount);
        setAmountWithGst(amountWithGst);
    }

    const changePlan = (plan) => {
        tokenapi.post(upgradePlan, {
            plan: plan,
            planPeriod: selectedMonths,
            services: plan ? (plan === 'Standard' ? standardServices : basicServices) : selectedService
        })
        .then(res => {
            setMessage(res.data.message);
            if(res.data.code === 200) {
                props.viewNewPlan();
                setMessageType('success');
            } else {
                setMessageType('error');
            }
        })
        .catch(err => {
            console.error(err);
            setMessage('Something went wrong.');
            setMessageType('error');
        })
    }

    return (<>
        {message && <Message type={messageType} message={message} closeMessage={() => setMessage('')} />}
        {modal && <Modal closeModal={() => setModal(false)}>
            <div className={styles['plan-modal']}>
                <h3>Create your own plan <img src="./assets/images/times.png" alt="times" onClick={() => setModal(false)} /></h3>
                <div className={styles['plan-details']}>
                    <div className={styles['all-services']}>
                        {standardServices.map((item, i) => (
                            <div
                                onClick={() => addFeature({key: item.key, feature: item.feature, description: item.description, count: item.count, price: item.price})}
                                className={`${styles[selectedService.find(x => x.key === item.key) && 'selected']} ${styles['single-service']}`}
                                key={i}
                            >
                                {
                                    selectedService.find(x => x.key === item.key)
                                    ?<i className="fa fa-check-square-o"></i>
                                    :<i className="fa fa-square-o"></i>
                                }
                                <div className={styles['service-detail']}>
                                    <p>{item.feature} - <span>{item.price}rs</span></p>
                                    <span>{item.description}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={styles['plan-amount']}>
                        <label>Select months</label>
                        <select value={selectedMonths} onChange={e => {setSelectedMonths(e.target.value); calculateAmountFunction(customAmount, e.target.value);}}>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="6">6</option>
                            <option value="12">12</option>
                        </select>
                        <p>Amount <span>Rs. {customAmount}</span></p>
                        {selectedMonths > 1 && <p>Amount for {selectedMonths} months <span>Rs. {amount}</span></p>}
                        <p>GST(18%) <span>Rs. {gstAmount}</span></p>
                        <p>Total <span>Rs. {amountWithGst}</span></p>
                        <div className="mt-5 text-center">
                            {
                                selectedService.length > 0
                                ?<button className="btn btn-primary" onClick={() => changePlan("Custom")}>Proceed</button>
                                :<button className="btn btn-primary" disabled>Proceed</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Modal>}
        {activateBasic && <Modal closeModal={() => setActivateBasic(false)}>
            <div className={styles['activate-basic-modal']}>
                <h3>Activate basic plan first <img src="./assets/images/times.png" alt="times" onClick={() => setActivateBasic(false)} /></h3>
                <p>To proceed with selecting the this plan, please ensure that you first activate the Basic plan. This step is necessary to ensure a smooth transition and to unlock the full range of features available in this plan.</p>
                {/* <p>If you have any questions about the plans or need assistance with the activation process, feel free to reach out!</p> */}
                <button className="btn btn-primary" onClick={() => changePlan("Basic")}>Activate basic plan</button>
            </div>
        </Modal>}
        <div className={styles['plan-cards']}>
            <div className={styles['plan-card-content']}>
                <h2>Basic plan <span>Free</span></h2>
                {basicServices.map((item, i) => (
                    <p key={i}><img src="./assets/images/check1.png" alt="check" /> {item.feature}</p>
                ))}
                <center>
                    {
                        props.plan
                        ?<div className={styles['activate-button']} disabled>Activated</div>
                        :<div className={styles['activate-button']} onClick={() => changePlan("Basic")}>Activate</div>
                    }
                </center>
            </div>
            <div className={styles['plan-card-content']}>
                <h2>Standard plan <span>₹2,500 INR/month</span></h2>
                <p><img src="./assets/images/check1.png" alt="check" /> All Basic plan features</p>
                {standardServices.map((item, i) => (
                    <p key={i}><img src="./assets/images/check1.png" alt="check" /> {item.feature}</p>
                ))}
                <center>
                    {
                        props.plan
                        ?<div className={styles['activate-button']} onClick={() => changePlan("Standard")}>Activate</div>
                        :<div className={styles['activate-button']} onClick={() => setActivateBasic(true)}>Activate</div>
                    }
                </center>
            </div>
            <div className={styles['plan-card-content']}>
                <h2>Custom plan <span>Create your own plan</span></h2>
                <div onClick={() => {props.plan ? setModal(true) : setActivateBasic(true)}} className={styles['add-service']}>
                    <img src="./assets/images/plus.png" alt="plus" />
                </div>
            </div>
        </div>
    </>)
}
