import React, { useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import tokenapi, { requestCallBack } from '../../Base/TokenConfig';
import Navbar from '../Components/Navbar/Navbar';
import Message from '../../Layouts/Message/Message';
import styles from './request-callback.module.css';

export default function RequestCallback() {
    const captchaRef = useRef(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [date, setDate] = useState('');
    const [message1, setMessage1] = useState('');
    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState('');
    const [message, setMessage] = useState('');
  
    const validateFunction = (e) => {
        e.preventDefault();
        const token = captchaRef.current.getValue();
        if(token){
            submitFunction(token);
        } else {
            setMessageType('error');
            setMessage('Please fill the captcha first');
            setTimeout(() => {
                setMessage('');
            }, 5000);
        }
    }

    const submitFunction = (token) => {
        setLoading(true);
        tokenapi.post(requestCallBack, {
            name: name,
            email: email,
            phone: phone,
            date: date,
            message: message1,
            recaptchaToken: token
        })
        .then(res => {
            setLoading(false);
            setMessage(res.data.message);
            if(res.data.code === 200) {
                setMessageType('success');
                emptyValues();
            } else {
                setMessageType('error');
            }
        })
        .catch(err => {
            console.error(err);
            setLoading(false);
            setMessageType('error');
            setMessage('Something went wrong.');
        })
    }

    const emptyValues = () => {
        setName('');
        setEmail('');
        setPhone('');
        setMessage1('');
        setDate('');
    }

    return (<>
        {message && <Message type={messageType} message={message} closeMessage={() => setMessage('')} />}
        <Navbar heading='Request Callback' subheading='Fill the form in case of any query' />
        <div className="dashboard-content">
            <div className={styles['request-callback-container']}>
                <form className={styles['request-callback']} onSubmit={validateFunction}>
                    <div style={{textAlign:'center'}}>
                        <img src="./assets/images/request-callback.jpg" alt="request-callback" />
                    </div>
                    <div>
                        <h2>Please fill free to reach us out!</h2>
                        <div className={styles['request-callback-input']}>
                            <div>
                                <label>Name</label>
                                <input type="text" placeholder="Enter name here" value={name} onChange={e => setName(e.target.value)} required/>
                            </div>
                            <div>
                                <label>Email</label>
                                <input type="email" placeholder="Enter email here" value={email} onChange={e => setEmail(e.target.value)} required/>
                            </div>
                            <div>
                                <label>Phone</label>
                                <input type="text" placeholder="Enter phone here" value={phone} onChange={e => setPhone(e.target.value)} required/>
                            </div>
                            <div>
                                <label>Date</label>
                                <input type="date" placeholder="Enter date here" value={date} onChange={e => setDate(e.target.value)} required/>
                            </div>
                        </div>
                        <div>
                            <label>Message</label>
                            <textarea className="form-control mb-3" placeholder="Enter message here" rows="5" value={message1} onChange={e => setMessage1(e.target.value)} required></textarea>
                        </div>
                        <div>
                            <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY} ref={captchaRef} />
                            <br/>
                            <button type="submit" className="btn btn-primary" disabled={loading}>{loading ? 'Wait...' : 'Submit'}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>)
}
