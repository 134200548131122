import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Signup from './Components/Signup';
import Signin from './Components/Signin';
import styles from './login.module.css';

export default function Login(){
    const [ registration, setRegistration ] = useState(false);

    const changeFunction = (x) => setRegistration(x);

    return (<>
        <Helmet>
            <title>Login - ScanOnn | Qr Code Online</title>
            <meta name="description" content="Access your account to create, customize, and manage your QR codes. Log in now to enjoy personalized features & track performance." />
        </Helmet>
        <div className={styles['content']}>
            <div>
                <Link to="/"><img src="../assets/images/logo.png" alt="logo" /></Link>
                {registration ? <Signup changeFunction={changeFunction} /> : <Signin changeFunction={changeFunction} />}
            </div>
        </div>
        <p className={styles['copyright']}>© {new Date().getFullYear()} ScanOnn | All rights reserved</p>
    </>)
}
