import React, { useEffect } from 'react';
import styles from './message.module.css';

export default function Message(props){
    const type = props.type;
    const message = props.message;

    useEffect(() => {
        setTimeout(() => {
            props.closeMessage();
        }, 5000);
    })

    return (<>
        {(type === 'success' || type === 'error' || type === 'warning') && <div className={styles['message-box']}>
            <div>
                <span className="fa fa-bell-o"></span>
            </div>
            <div>
                <h5 className={styles[type]}>
                    {type}
                    <img src="../assets/images/times-cross.png" alt="times" onClick={() => props.closeMessage()} />
                </h5>
                <p>{message}</p>
            </div>
        </div>}
    </>)
}
