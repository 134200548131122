import React, { useState, useEffect } from 'react';
import tokenapi, { getUserPlan } from '../../Base/TokenConfig';
import Navbar from '../Components/Navbar/Navbar';
import MorePlans from './Components/MorePlans';
import styles from './plans.module.css';

export default function Plans() {
    const [tabName, setTabName] = useState('your-plan');
    const [plan, setPlan] = useState('');
    const [planServices, setPlanServices] = useState([]);

    useEffect(() => {
        fetchPlanDetails();
    }, [])

    const fetchPlanDetails = () => {
        tokenapi.get(getUserPlan)
        .then(res => {
            if(res.data.code === 200) {
                setPlan(res.data.result.plan);
                setPlanServices(res.data.result.services);
            }
        })
        .catch(err => {
            console.error(err);
        })
    }

    return (<>
        <Navbar heading='Plans' subheading='Choose a plan thats suits you well' />
        <div className="dashboard-content">
            {planServices.length > 0 && <div className={styles['plan-tabs']}>
                <div className={styles[tabName === 'your-plan' && 'selected']} onClick={() => setTabName('your-plan')}>Your plan</div>
                <div className={styles[tabName === 'more-plans' && 'selected']} onClick={() => setTabName('more-plans')}>More plans</div>
            </div>}
            {tabName === 'your-plan' && <>
                {
                    planServices.length > 0
                    ?<div className={styles['plan-services']}>
                        <div>
                            <div>Service</div>
                            <div>Price</div>
                            <div>Start date</div>
                            <div>End date</div>
                        </div>
                        {planServices.map((item, i) => (
                            <div className={styles['plan-service']} key={i}>
                                <div><p>{item.feature} {item.count && <i>(upto {item.count})</i>} <span>{item.description}</span></p></div>
                                <div>{item.price}</div>
                                <div>{`${new Date(item.updatedAt ? item.updatedAt : item.startDate)}`.substr(4, 11)}</div>
                                <div>{`${new Date(item.endDate)}`.substr(4, 11)}</div>
                            </div>
                        ))}
                    </div>
                    :<div className={styles['no-services-found']}>
                        <img src="./assets/images/plan-not-found.webp" alt="check" />
                        <p>You haven't selected any services for your account. You can begin by adding the Basic (Free) plan or explore additional plans.</p>
                        <button className="btn btn-primary" onClick={() => setTabName('more-plans')}>View Plans</button>
                    </div>
                }
            </>}
            {tabName === 'more-plans' && <MorePlans viewNewPlan={() => {setTabName('your-plan'); fetchPlanDetails();}} plan={plan} />}
        </div>
    </>)
}
