import React, { useEffect, useState } from 'react';
import adminapi, { fetchServices, deleteService, createService, updateService } from '../../Base/AdminConfig';
import Message from '../../Layouts/Message/Message';
import Modal from '../../Layouts/Modal/Modal';
import styles from './services.module.css';

export default function Services() {
    const [addModal, setAddModal] = useState(false);
    const [detailModal, setDetailModal] = useState(false);
    const [detail, setDetail] = useState('');
    const [messageType, setMessageType] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [services, setServices] = useState([]);
    const [feature, setFeature] = useState('');
    const [description, setDescription] = useState('');
    const [key, setKey] = useState('');
    const [count, setCount] = useState(0);
    const [price, setPrice] = useState(0);
    const [plan, setPlan] = useState('');

    useEffect(() => {
        fetchFunction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchFunction = () => {
        adminapi.get(fetchServices)
        .then(res => {
            if(res.data.code === 200) {
                setServices(res.data.result);
            } else {
                setServices([]);
            }
        })
        .catch(err => {
            console.error(err);
        })
    }

    const openModal = (x) => {
        setDetailModal(true);
        setDetail(x);
        setFeature(x.feature);
        setDescription(x.description);
        setKey(x.key);
        setCount(x.count);
        setPrice(x.price);
        setPlan(x.plan);
    }

    const closeModal = () => {
        setDetailModal(false);
        setAddModal(false);
        clearInput();
    }

    const clearInput = () => {
        setFeature('');
        setDescription('');
        setKey('');
        setCount(0);
        setPrice(0);
        setPlan('');
    }

    const createFunction = (e) => {
        e.preventDefault();
        setLoading(true);
        adminapi.post(createService, {
            feature: feature,
            description: description,
            key: key,
            count: count,
            price: price,
            plan: plan
        })
        .then(res => {
            setLoading(false);
            setMessage(res.data.message);
            if(res.data.code === 200) {
                fetchFunction();
                closeModal();
                setMessageType('success');
            } else {
                setMessageType('error');
            }
        })
        .catch(err => {
            setLoading(false);
            console.error(err);
        })
    }

    const updateFunction = (e) => {
        e.preventDefault();
        setLoading(true);
        adminapi.post(updateService, {
            feature: feature,
            description: description,
            key: key,
            count: count,
            price: price,
            plan: plan
        })
        .then(res => {
            setLoading(false);
            setMessage(res.data.message);
            if(res.data.code === 200) {
                fetchFunction();
                closeModal();
                setMessageType('success');
            } else {
                setMessageType('error');
            }
        })
        .catch(err => {
            setLoading(false);
            console.error(err);
        })
    }

    const deleteFunction = (detail) => {
        setLoading(true);
        adminapi.delete(deleteService + detail._id)
        .then(res => {
            setLoading(false);
            setMessage(res.data.message);
            if(res.data.code === 200) {
                fetchFunction();
                setDetailModal(false);
                setMessageType('success');
            } else {
                setMessageType('error');
            }
        })
        .catch(err => {
            setLoading(false);
            console.error(err);
        })
    }

    return (<>
        {message && <Message type={messageType} message={message} closeMessage={() => setMessage('')} />}
        {addModal && <Modal closeModal={closeModal}>
            <div className={styles["modal-content"]}>
                <div className={styles['modal-heading']}>Add service <img src="../assets/images/times.png" onClick={() => closeModal()} alt="times" /></div>
                <form onSubmit={createFunction}>
                    <label>Feature</label>
                    <input type="text" placeholder="Enter feature here" value={feature} onChange={(e) => setFeature(e.target.value)} required/>
                    <label>Description</label>
                    <input type="text" placeholder="Enter description here" value={description} onChange={(e) => setDescription(e.target.value)} required/>
                    <label>Key</label>
                    <input type="text" placeholder="Enter key here" value={key} onChange={(e) => setKey(e.target.value)} required/>
                    <label>Count</label>
                    <input type="number" placeholder="Enter count here" value={count} onChange={(e) => setCount(e.target.value)} required/>
                    <label>Price</label>
                    <input type="number" placeholder="Enter price here" value={price} onChange={(e) => setPrice(e.target.value)} required/>
                    <label>Plan</label>
                    <select value={plan} onChange={(e) => setPlan(e.target.value)} required>
                        <option value="">--- Select one ---</option>
                        <option value="Basic">Basic</option>
                        <option value="Standard">Standard</option>
                        <option value="Custom">Custom</option>
                    </select>
                    <button type="submit" className="btn btn-primary" disabled={loading}>{loading ? 'Wait ...' : 'Submit'}</button>
                </form>
            </div>
        </Modal>}
        {detailModal && <Modal closeModal={closeModal}>
            <div className={styles["modal-content"]}>
                <div className={styles['modal-heading']}>Service details <img src="../assets/images/times.png" onClick={() => closeModal()} alt="times" /></div>
                <div>
                    <p><span>Key:</span> {detail.key}</p>
                    <p><span>Created at:</span> {`${new Date(detail.createdAt)}`.substr(0, 24)}</p>
                    <p onClick={() => deleteFunction(detail)} className={styles['delete-service']}><span className="fa fa-trash-o"></span> Delete service</p>
                </div>
                <form onSubmit={updateFunction}>
                    <label>Feature</label>
                    <input type="text" placeholder="Enter feature here" value={feature} onChange={(e) => setFeature(e.target.value)} required/>
                    <label>Description</label>
                    <input type="text" placeholder="Enter description here" value={description} onChange={(e) => setDescription(e.target.value)} required/>
                    <label>Count</label>
                    <input type="number" placeholder="Enter count here" value={count} onChange={(e) => setCount(e.target.value)} required/>
                    <label>Price</label>
                    <input type="number" placeholder="Enter price here" value={price} onChange={(e) => setPrice(e.target.value)} required/>
                    <label>Plan</label>
                    <select value={plan} onChange={(e) => setPlan(e.target.value)} required>
                        <option value="">--- Select one ---</option>
                        <option value="Basic">Basic</option>
                        <option value="Standard">Standard</option>
                        <option value="Custom">Custom</option>
                    </select>
                    <button className="btn btn-primary mt-3" disabled={loading}>{loading ? 'Wait ...' : 'Submit'}</button>
                </form>
            </div>
        </Modal>}
        <div className={styles['title-add-button']}>
            <h1>Services <span>({services.length})</span></h1>
            <button className="btn btn-primary" onClick={() => setAddModal(true)}>Add service</button>
        </div>
        <div className={styles['service-list']}>
            <div>
                <div><p>#</p></div>
                <div className={styles['main-details']}>
                    <p>Feature</p>
                    <p>Description</p>
                    <p>Key</p>
                    <p>Plan</p>
                    <p>Count</p>
                    <p>Price</p>
                </div>
                <div><p>Details</p></div>
            </div>
            {services.map((item, i) => (
                <div key={item._id}>
                    <div><p><span>#</span> {i + 1}</p></div>
                    <div className={styles['main-details']}>
                        <p><span>Feature</span> {item.feature}</p>
                        <p><span>Description</span> {item.description}</p>
                        <p><span>Key</span> {item.key}</p>
                        <p><span>Plan</span> {item.plan}</p>
                        <p><span>Count</span> {item.count}</p>
                        <p><span>Price</span> {item.price}</p>
                    </div>
                    <div><p className={styles['details']} onClick={() => openModal(item)}>Details</p></div>
                </div>
            ))}
        </div>
    </>)
}