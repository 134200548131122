import React from 'react';
import { getCategory } from '../../../Utils/Common';
import VisitingCard from './QRCodeTypes/VisitingCard';
import URL from './QRCodeTypes/URL';
import WhatsApp from './QRCodeTypes/WhatsApp';
import Text from './QRCodeTypes/Text';
import Media from './QRCodeTypes/Media';
import PDF from './QRCodeTypes/PDF';
import Location from './QRCodeTypes/Location';
import UPI from './QRCodeTypes/UPI';
import styles from '../create-qr-code.module.css';

export default function Content(props){
    const details = getCategory();
    const category = JSON.parse(details);

    const categories = ["Visiting Card", "URL", "WhatsApp", "Text", "Media", "PDF", "Location", "UPI"];

    return (<>
        <div>
            <div className={styles['required-note']}><span>Note:</span> An asterisk (*) indicates a required field</div>
            {
                categories.indexOf(category.categoryName) === -1
                ?"Coming soon"
                :<>
                    {category.categoryName === 'Visiting Card' && <VisitingCard stepChangeFunction={props.stepChangeFunction} categoryId={category.categoryId} />}
                    {category.categoryName === 'URL' && <URL stepChangeFunction={props.stepChangeFunction} categoryId={category.categoryId} />}
                    {category.categoryName === 'WhatsApp' && <WhatsApp stepChangeFunction={props.stepChangeFunction} categoryId={category.categoryId} />}
                    {category.categoryName === 'Text' && <Text stepChangeFunction={props.stepChangeFunction} categoryId={category.categoryId} />}
                    {category.categoryName === 'Media' && <Media stepChangeFunction={props.stepChangeFunction} categoryId={category.categoryId} />}
                    {category.categoryName === 'PDF' && <PDF stepChangeFunction={props.stepChangeFunction} categoryId={category.categoryId} />}
                    {category.categoryName === 'Location' && <Location stepChangeFunction={props.stepChangeFunction} categoryId={category.categoryId} />}
                    {category.categoryName === 'UPI' && <UPI stepChangeFunction={props.stepChangeFunction} categoryId={category.categoryId} />}
                </>
            }
        </div>
    </>)
}
