import React, { useEffect, useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import styles from '../../create-qr-code.module.css';

export default function Location(props) {
    const [title, setTitle] = useState('');
    const [location, setLocation] = useState('');

    useEffect(() => {
        if(sessionStorage.getItem('qr-content')) {
            const qrContent = JSON.parse(sessionStorage.getItem('qr-content'));
            setTitle(qrContent.title);
        }
    }, [])

    const createFunction = (e) => {
        e.preventDefault();
        sessionStorage.setItem('qr-content', JSON.stringify({ title, location }));
        props.stepChangeFunction(3);
    }

    const handlePlaceSelect = (place) => {
        setLocation(place.label);
    };

    return (<>
        <form onSubmit={createFunction}>
            <div className={styles['qr-title']}>
                <label>Title*</label>
                <input type="text" placeholder="Enter QR title here" value={title} onChange={e => setTitle(e.target.value)} required/>
            </div>
            <div className={styles['section-heading']}>
                <hr/>
                <h3>Details</h3>
            </div>
            <div>
                <label>Location*</label>
                <GooglePlacesAutocomplete
                    apiKey={process.env.REACT_APP_GOOGLE_PLACES}
                    selectProps={{
                        placeholder: 'Enter a location',
                        onChange: handlePlaceSelect
                    }}
                />
            </div>
            <button type="submit" className="btn btn-primary">Next</button>
        </form>
    </>)
}