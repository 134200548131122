import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Message from '../../../Layouts/Message/Message';
import styles from '../my-qr-code-detail.module.css';
import Modal from '../../../Layouts/Modal/Modal';

export default function QrDetails(props) {
    const data = props.data;
    const empty = 'Incomplete';
    const [fileType, setFileType] = useState('');
    const [fileUrl, setFileUrl] = useState('');
    const [showMediaModal, setShowMediaModal] = useState(false);
    const [messageType, setMessageType] = useState('');
    const [message, setMessage] = useState('');

    const copytext = () => {
        setMessageType('success');
        setMessage('Link copied clipboard.');
    }

    const openMedia = (url) => {
        const fileType = url.split('.').reverse()[0];
        if(fileType === 'mp3' || fileType === 'aac' || fileType === 'wav') {
            setFileType('Audio');
        } else if(fileType === 'mp4' || fileType === 'mov' || fileType === 'wmv' || fileType === 'avi' || fileType === 'mkv' || fileType === 'webm') {
            setFileType('Video');
        } else if(fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png') {
            setFileType('Image');
        } else if(fileType === 'pdf') {
            setFileType('PDF');
        }
        setFileUrl(url);
        setShowMediaModal(true);
    }

    return (<>
        {message && <Message type={messageType} message={message} closeMessage={() => setMessage('')} />}
        {showMediaModal && <Modal closeModal={() => setShowMediaModal(false)}>
            <div className={styles['media-modal']}>
                {fileType === 'Audio' && <>
                    <audio controls>
                        <source src={fileUrl} type="audio/mpeg" />
                    </audio>
                </>}
                {fileType === 'Video' && <>
                    <video width="320" height="240" controls autoplay>
                        <source src={fileUrl} type="video/mp4" />
                    </video>
                </>}
                {fileType === 'Image' && <>
                    <img src={fileUrl} alt="media" />
                </>}
                {fileType === 'PDF' && <>
                    <iframe src={`${fileUrl}#toolbar=0`} style={{width: '100%',height: '770px'}} title="pdf"></iframe>
                </>}
            </div>
        </Modal>}
        {data.categoryName === "UPI" && <div className={styles['qr-detail']}>
            <div className={styles['qr-basic-detail']}>
                <img src={data.s3Url} alt="QR" />
            </div>
            <p className={`${styles['qr-detail-item']} my-3`}>
                <span>Link: </span>
                {data.encWebUrl}
                <CopyToClipboard text={data.encWebUrl} onCopy={() => copytext()}>
                    <i className={`fa fa-copy ${styles['copy-icon']}`}></i>
                </CopyToClipboard>
            </p>
            <div className={styles['qr-detail-section']}>
                <p className={styles['qr-detail-item']}><span>Title:</span> {data.title}</p>
                <p className={styles['qr-detail-item']}><span>UPI ID:</span> {data.upiId}</p>
                <p className={styles['qr-detail-item']}><span>Name:</span> {data.name}</p>
                <p className={styles['qr-detail-item']}><span>Amount:</span> {data.amount}</p>
            </div>
            <div className={styles['qr-detail-section-heading']}>QR detail</div>
            <div className={styles['qr-detail-section']}>
                {data.totalScans && <p className={styles['qr-detail-item']}><span>Total scans:</span> {data.totalScans}</p>}
                {data.uniqueViews && <p className={styles['qr-detail-item']}><span>Unique views:</span> {data.uniqueViews}</p>}
                {data.createdAt && <p className={styles['qr-detail-item']}><span>Created at:</span> {`${new Date(data.createdAt)}`.substr(0,24)}</p>}
                {data.updatedAt && <p className={styles['qr-detail-item']}><span>Updated at:</span> {`${new Date(data.updatedAt)}`.substr(0,24)}</p>}
            </div>
        </div>}
        {data.categoryName === "Location" && <div className={styles['qr-detail']}>
            <div className={styles['qr-basic-detail']}>
                <img src={data.s3Url} alt="QR" />
            </div>
            <p className={`${styles['qr-detail-item']} my-3`}>
                <span>Link: </span>
                {data.encWebUrl}
                <CopyToClipboard text={data.encWebUrl} onCopy={() => copytext()}>
                    <i className={`fa fa-copy ${styles['copy-icon']}`}></i>
                </CopyToClipboard>
            </p>
            <div className={styles['qr-detail-section']}>
                <p className={styles['qr-detail-item']}><span>Title:</span> {data.title}</p>
                <p className={styles['qr-detail-item']}><span>Location:</span> {data.location}</p>
            </div>
            <div className={styles['qr-detail-section-heading']}>QR detail</div>
            <div className={styles['qr-detail-section']}>
                {data.totalScans && <p className={styles['qr-detail-item']}><span>Total scans:</span> {data.totalScans}</p>}
                {data.uniqueViews && <p className={styles['qr-detail-item']}><span>Unique views:</span> {data.uniqueViews}</p>}
                {data.createdAt && <p className={styles['qr-detail-item']}><span>Created at:</span> {`${new Date(data.createdAt)}`.substr(0,24)}</p>}
                {data.updatedAt && <p className={styles['qr-detail-item']}><span>Updated at:</span> {`${new Date(data.updatedAt)}`.substr(0,24)}</p>}
            </div>
        </div>}
        {data.categoryName === "PDF" && <div className={styles['qr-detail']}>
            <div className={styles['qr-basic-detail']}>
                <img src={data.s3Url} alt="QR" />
            </div>
            <p className={`${styles['qr-detail-item']} my-3`}>
                <span>Link: </span>
                {data.encWebUrl}
                <CopyToClipboard text={data.encWebUrl} onCopy={() => copytext()}>
                    <i className={`fa fa-copy ${styles['copy-icon']}`}></i>
                </CopyToClipboard>
            </p>
            <div className={styles['qr-detail-section']}>
                <p className={styles['qr-detail-item']}><span>Title:</span> {data.title}</p>
                <p className={styles['qr-detail-item']}><span>Media:</span> <i className={styles['view-media']} onClick={() => openMedia(data.pdfUrl)}>view media</i></p>
            </div>
            <div className={styles['qr-detail-section-heading']}>QR detail</div>
            <div className={styles['qr-detail-section']}>
                {data.totalScans && <p className={styles['qr-detail-item']}><span>Total scans:</span> {data.totalScans}</p>}
                {data.uniqueViews && <p className={styles['qr-detail-item']}><span>Unique views:</span> {data.uniqueViews}</p>}
                {data.createdAt && <p className={styles['qr-detail-item']}><span>Created at:</span> {`${new Date(data.createdAt)}`.substr(0,24)}</p>}
                {data.updatedAt && <p className={styles['qr-detail-item']}><span>Updated at:</span> {`${new Date(data.updatedAt)}`.substr(0,24)}</p>}
            </div>
        </div>}
        {data.categoryName === "Media" && <div className={styles['qr-detail']}>
            <div className={styles['qr-basic-detail']}>
                <img src={data.s3Url} alt="QR" />
            </div>
            <p className={`${styles['qr-detail-item']} my-3`}>
                <span>Link: </span>
                {data.encWebUrl}
                <CopyToClipboard text={data.encWebUrl} onCopy={() => copytext()}>
                    <i className={`fa fa-copy ${styles['copy-icon']}`}></i>
                </CopyToClipboard>
            </p>
            <div className={styles['qr-detail-section']}>
                <p className={styles['qr-detail-item']}><span>Title:</span> {data.title}</p>
                <p className={styles['qr-detail-item']}><span>Media:</span> <i className={styles['view-media']} onClick={() => openMedia(data.mediaUrl)}>view media</i></p>
            </div>
            <div className={styles['qr-detail-section-heading']}>QR detail</div>
            <div className={styles['qr-detail-section']}>
                {data.totalScans && <p className={styles['qr-detail-item']}><span>Total scans:</span> {data.totalScans}</p>}
                {data.uniqueViews && <p className={styles['qr-detail-item']}><span>Unique views:</span> {data.uniqueViews}</p>}
                {data.createdAt && <p className={styles['qr-detail-item']}><span>Created at:</span> {`${new Date(data.createdAt)}`.substr(0,24)}</p>}
                {data.updatedAt && <p className={styles['qr-detail-item']}><span>Updated at:</span> {`${new Date(data.updatedAt)}`.substr(0,24)}</p>}
            </div>
        </div>}
        {data.categoryName === "Text" && <div className={styles['qr-detail']}>
            <div className={styles['qr-basic-detail']}>
                <img src={data.s3Url} alt="QR" />
            </div>
            <p className={`${styles['qr-detail-item']} my-3`}>
                <span>Link: </span>
                {data.encWebUrl}
                <CopyToClipboard text={data.encWebUrl} onCopy={() => copytext()}>
                    <i className={`fa fa-copy ${styles['copy-icon']}`}></i>
                </CopyToClipboard>
            </p>
            <div className={styles['qr-detail-section']}>
                <p className={styles['qr-detail-item']}><span>Title:</span> {data.title}</p>
                <p className={styles['qr-detail-item']}><span>Text:</span> {data.text}</p>
                <p className={styles['qr-detail-item']}><span>Description:</span> {data.description}</p>
            </div>
            <div className={styles['qr-detail-section-heading']}>QR detail</div>
            <div className={styles['qr-detail-section']}>
                {data.totalScans && <p className={styles['qr-detail-item']}><span>Total scans:</span> {data.totalScans}</p>}
                {data.uniqueViews && <p className={styles['qr-detail-item']}><span>Unique views:</span> {data.uniqueViews}</p>}
                {data.createdAt && <p className={styles['qr-detail-item']}><span>Created at:</span> {`${new Date(data.createdAt)}`.substr(0,24)}</p>}
                {data.updatedAt && <p className={styles['qr-detail-item']}><span>Updated at:</span> {`${new Date(data.updatedAt)}`.substr(0,24)}</p>}
            </div>
        </div>}
        {data.categoryName === "WhatsApp" && <div className={styles['qr-detail']}>
            <div className={styles['qr-basic-detail']}>
                <img src={data.s3Url} alt="QR" />
            </div>
            <p className={`${styles['qr-detail-item']} my-3`}>
                <span>Link: </span>
                {data.encWebUrl}
                <CopyToClipboard text={data.encWebUrl} onCopy={() => copytext()}>
                    <i className={`fa fa-copy ${styles['copy-icon']}`}></i>
                </CopyToClipboard>
            </p>
            <div className={styles['qr-detail-section']}>
                <p className={styles['qr-detail-item']}><span>Title:</span> {data.title}</p>
                <p className={styles['qr-detail-item']}><span>Phone:</span> {data.countryCode} {data.phone}</p>
                <p className={styles['qr-detail-item']}><span>Message:</span> {data.message}</p>
            </div>
            <div className={styles['qr-detail-section-heading']}>QR detail</div>
            <div className={styles['qr-detail-section']}>
                {data.totalScans && <p className={styles['qr-detail-item']}><span>Total scans:</span> {data.totalScans}</p>}
                {data.uniqueViews && <p className={styles['qr-detail-item']}><span>Unique views:</span> {data.uniqueViews}</p>}
                {data.createdAt && <p className={styles['qr-detail-item']}><span>Created at:</span> {`${new Date(data.createdAt)}`.substr(0,24)}</p>}
                {data.updatedAt && <p className={styles['qr-detail-item']}><span>Updated at:</span> {`${new Date(data.updatedAt)}`.substr(0,24)}</p>}
            </div>
        </div>}
        {data.categoryName === "URL" && <div className={styles['qr-detail']}>
            <div className={styles['qr-basic-detail']}>
                <img src={data.s3Url} alt="QR" />
            </div>
            <p className={`${styles['qr-detail-item']} my-3`}>
                <span>Link: </span>
                {data.encWebUrl}
                <CopyToClipboard text={data.encWebUrl} onCopy={() => copytext()}>
                    <i className={`fa fa-copy ${styles['copy-icon']}`}></i>
                </CopyToClipboard>
            </p>
            <div className={styles['qr-detail-section']}>
                <p className={styles['qr-detail-item']}><span>Title:</span> {data.title}</p>
                <p className={styles['qr-detail-item']}><span>URL:</span> {data.url}</p>
            </div>
            <div className={styles['qr-detail-section-heading']}>QR detail</div>
            <div className={styles['qr-detail-section']}>
                {data.totalScans && <p className={styles['qr-detail-item']}><span>Total scans:</span> {data.totalScans}</p>}
                {data.uniqueViews && <p className={styles['qr-detail-item']}><span>Unique views:</span> {data.uniqueViews}</p>}
                {data.createdAt && <p className={styles['qr-detail-item']}><span>Created at:</span> {`${new Date(data.createdAt)}`.substr(0,24)}</p>}
                {data.updatedAt && <p className={styles['qr-detail-item']}><span>Updated at:</span> {`${new Date(data.updatedAt)}`.substr(0,24)}</p>}
            </div>
        </div>}
        {data.categoryName === "Visiting Card" && <div className={styles['qr-detail']}>
            <div className={styles['qr-basic-detail']}>
                <img src={data.s3Url} alt="QR" />
                {
                    data.photo
                    ?<img src={data.photo} alt={data.name} />
                    :<img src={`https://robohash.org/${data.requestId}`} alt={data.name} />
                }
            </div>
            <p className={`${styles['qr-detail-item']} my-3`}>
                <span>Link: </span>
                {data.encWebUrl}
                <CopyToClipboard text={data.encWebUrl} onCopy={() => copytext()}>
                    <i className={`fa fa-copy ${styles['copy-icon']}`}></i>
                </CopyToClipboard>
            </p>
            <p className={`${styles['qr-detail-item']} my-3`}><span>About:</span> {data.about}</p>
            <div className={styles['qr-detail-section']}>
                <p className={styles['qr-detail-item']}><span>Title:</span> {data.title}</p>
                <p className={styles['qr-detail-item']}><span>Name:</span> {data.name}</p>
                <p className={styles['qr-detail-item']}><span>Designation:</span> {data.designation ? data.designation : <i>{empty}</i>}</p>
                <p className={styles['qr-detail-item']}><span>Organization:</span> {data.organization ? data.organization : <i>{empty}</i>}</p>
                <p className={styles['qr-detail-item']}><span>Website:</span> {data.website ? data.website : <i>{empty}</i>}</p>
            </div>
            <div className={styles['qr-detail-section-heading']}>Contact information</div>
            <div className={styles['qr-detail-section']}>
                <p className={styles['qr-detail-item']}><span>Phone(Work):</span> {data.phoneWork ? data.phoneWork : <i>{empty}</i>}</p>
                <p className={styles['qr-detail-item']}><span>Phone(Private):</span> {data.phonePrivate ? data.phonePrivate : <i>{empty}</i>}</p>
                <p className={styles['qr-detail-item']}><span>Landline:</span> {data.landline ? data.landline : <i>{empty}</i>}</p>
                <p className={styles['qr-detail-item']}><span>Address:</span> {data.address ? data.address : <i>{empty}</i>}</p>
                <p className={styles['qr-detail-item']}><span>Fax:</span> {data.fax ? data.fax : <i>{empty}</i>}</p>
                <p className={styles['qr-detail-item']}><span>Email:</span> {data.email ? data.email : <i>{empty}</i>}</p>
            </div>
            <div className={styles['qr-detail-section-heading']}>Social media</div>
            <div className={styles['qr-detail-section']}>
                <p className={styles['qr-detail-item']}><span>Facebook:</span> {data.socialMedia?.facebook ? data.socialMedia.facebook : <i>{empty}</i>}</p>
                <p className={styles['qr-detail-item']}><span>Twitter:</span> {data.socialMedia?.twitter ? data.socialMedia.twitter : <i>{empty}</i>}</p>
                <p className={styles['qr-detail-item']}><span>Instagram:</span> {data.socialMedia?.instagram ? data.socialMedia.instagram : <i>{empty}</i>}</p>
                <p className={styles['qr-detail-item']}><span>Youtube:</span> {data.socialMedia?.youtube ? data.socialMedia.youtube : <i>{empty}</i>}</p>
                <p className={styles['qr-detail-item']}><span>Linkedin:</span> {data.socialMedia?.linkedin ? data.socialMedia.linkedin : <i>{empty}</i>}</p>
            </div>
            <div className={styles['qr-detail-section-heading']}>QR detail</div>
            <div className={styles['qr-detail-section']}>
                <p className={styles['qr-detail-item']}><span>Total scans:</span> {data.totalScans ? data.totalScans : <i>{empty}</i>}</p>
                <p className={styles['qr-detail-item']}><span>Unique views:</span> {data.uniqueViews ? data.uniqueViews : <i>{empty}</i>}</p>
                <p className={styles['qr-detail-item']}><span>Created at:</span> {data.createdAt ? `${new Date(data.createdAt)}`.substr(0,24) : <i>{empty}</i>}</p>
                <p className={styles['qr-detail-item']}><span>Updated at:</span> {data.updatedAt ? `${new Date(data.updatedAt)}`.substr(0,24) : <i>{empty}</i>}</p>
            </div>
        </div>}
    </>)
}