import React, { useEffect, useState } from 'react';
import styles from '../../create-qr-code.module.css';

export default function Text(props) {
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        if(sessionStorage.getItem('qr-content')) {
            const qrContent = JSON.parse(sessionStorage.getItem('qr-content'));
            setTitle(qrContent.title);
            setText(qrContent.text);
            setDescription(qrContent.description);
        }
    }, [])

    const createFunction = (e) => {
        e.preventDefault();
        sessionStorage.setItem('qr-content', JSON.stringify({ title, text, description }));
        props.stepChangeFunction(3);
    }

    return (<>
        <form onSubmit={createFunction}>
            <div className={styles['qr-title']}>
                <label>Title*</label>
                <input type="text" placeholder="Enter QR title here" value={title} onChange={e => setTitle(e.target.value)} required/>
            </div>
            <div className={styles['section-heading']}>
                <hr/>
                <h3>Details</h3>
            </div>
            <div className={styles['create-qr-fields']}>
                <div>
                    <label>Description*</label>
                    <input type="text" placeholder="Enter description" value={description} onChange={e => setDescription(e.target.value)} required/>
                </div>
            </div>
            <div className={styles['create-qr-fields']}>
                <div>
                    <label>Text*</label>
                    <textarea placeholder="Enter text" rows="5" className="form-control" value={text} onChange={e => setText(e.target.value)} required></textarea>
                </div>
            </div>
            <button type="submit" className="btn btn-primary">Next</button>
        </form>
    </>)
}