import React, { useState, useEffect } from "react";
import { PieChart, Pie, ResponsiveContainer, Cell } from "recharts";
import adminapi, { totalQrCodeCount, totalUserCount, accountTypeCount, accountStatusCount, totalQrCodeTypeCount, totalScannedQrCount, getTopCategories, getTopUsers } from "../../Base/AdminConfig";
import styles from "./dashboard.module.css";

export default function Users() {
    const [qrCount, setQrCount] = useState("");
    const [userCount, setUserCount] = useState("");
    const [qrScansCount, setQrScansCount] = useState("");
    const [accountTypeArray, setAccountTypeArray] = useState([]);
    const [accountStatusArray, setAccountStatusArray] = useState([]);
    const [qrTypeArray, setQrTypeArray] = useState([]);
    const [topCategories, setTopCategories] = useState([]);
    const [topUsers, setTopUsers] = useState([]);
    const colors = ["#03CEA4", "#EAC435"];
    var array1 = [];
    var array2 = [];
    var array3 = [];

    useEffect(() => {
        fetchQrCount();
        fetchUserCount();
        fetchQrScansCount();
        fetchAccountTypeCount("google");
        fetchAccountTypeCount("normal");
        fetchAccountStatusCount("active");
        fetchAccountStatusCount("inactive");
        fetchQrTypeCount("static");
        fetchQrTypeCount("dynamic");
        fetchTopCategories();
        fetchTopUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchQrCount = () => {
        adminapi
        .get(totalQrCodeCount)
        .then((res) => {
            if (res.data.code === 200) {
                setQrCount(res.data.count);
            } else {
                setQrCount(0);
            }
        })
        .catch((err) => {
            console.error(err);
        });
    };

    const fetchUserCount = () => {
        adminapi
        .get(totalUserCount)
        .then((res) => {
            if (res.data.code === 200) {
                setUserCount(res.data.count);
            } else {
                setUserCount(0);
            }
        })
        .catch((err) => {
            console.error(err);
        });
    };

    const fetchQrScansCount = () => {
        adminapi
        .get(totalScannedQrCount)
        .then((res) => {
            if (res.data.code === 200) {
                setQrScansCount(res.data.count);
            } else {
                setQrScansCount(0);
            }
        })
        .catch((err) => {
            console.error(err);
        });
    };

    const fetchAccountTypeCount = (x) => {
        adminapi
        .get(accountTypeCount + x)
        .then((res) => {
            if (res.data.code === 200) {
                if (x === "google") {
                    array1.push({
                        name: "Google",
                        count: res.data.count,
                    });
                } else if (x === "normal") {
                    array1.push({
                        name: "Normal",
                        count: res.data.count,
                    });
                    setAccountTypeArray(array1);
                }
            }
        })
        .catch((err) => {
            console.error(err);
        });
    };

    const fetchAccountStatusCount = (x) => {
        adminapi
        .get(accountStatusCount + x)
        .then((res) => {
            if (res.data.code === 200) {
                if (x === "active") {
                    array2.push({
                        name: "Active",
                        count: res.data.count,
                    });
                } else if (x === "inactive") {
                    array2.push({
                        name: "Inactive",
                        count: res.data.count,
                    });
                    setAccountStatusArray(array2);
                }
            }
        })
        .catch((err) => {
            console.error(err);
        });
    };

    const fetchQrTypeCount = (x) => {
        adminapi
        .get(totalQrCodeTypeCount + x)
        .then((res) => {
            if (res.data.code === 200) {
                if (x === "static") {
                    array3.push({
                        name: "Static",
                        count: res.data.count,
                    });
                } else if (x === "dynamic") {
                    array3.push({
                        name: "Dynamic",
                        count: res.data.count,
                    });
                    setQrTypeArray(array3);
                }
            }
        })
        .catch((err) => {
            console.error(err);
        });
    };

    const fetchTopCategories = () => {
        adminapi
        .get(getTopCategories)
        .then((res) => {
            if(res.data.code === 200) {
                setTopCategories(res.data.categories);
            }
        })
        .catch((err) => {
            console.error(err);
        });
    };

    const fetchTopUsers = () => {
        adminapi
        .get(getTopUsers)
        .then((res) => {
            if(res.data.code === 200) {
                setTopUsers(res.data.users);
            }
        })
        .catch((err) => {
            console.error(err);
        });
    };

    return (<>
        <h1>Dashboard</h1>
        <div className={styles["admin-analytics"]}>
            <div className={styles.container}>
                <div className={styles.subdiv}>
                    <p>Total user</p>
                    <span>{userCount}</span>
                </div>
                <div className={styles.subdiv}>
                    <p>Total QRs</p>
                    <span>{qrCount}</span>
                </div>
                <div className={styles.subdiv}>
                    <p>Total scans</p>
                    <span>{qrScansCount}</span>
                </div>
            </div>
            <div className={styles.main}>
                <div className={styles.graphbox}>
                    <h2>Account type</h2>
                    <ResponsiveContainer width="100%" height={220} className={styles.responsivecontainer}>
                        <PieChart width={250} height={250}>
                            <Pie
                                data={accountTypeArray}
                                dataKey="count"
                                nameKey="percentage"
                                cx="50%"
                                cy="50%"
                                innerRadius={50}
                                outerRadius={80}
                            >
                                {accountTypeArray.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={colors[index]} />
                                ))}
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                    <div className={styles.insidegraphbox}>
                        <div className={styles.graphdata}>
                            {accountTypeArray.map((item, i) => (<p key={i}>
                                <span style={{ backgroundColor: colors[i] }}></span>{" "}
                                {item.name} - {item.count}
                            </p>))}
                        </div>
                    </div>
                </div>
                <div className={styles.graphbox}>
                    <h2>Account status</h2>
                    <ResponsiveContainer width="100%" height={220} className={styles.responsivecontainer}>
                        <PieChart width={250} height={250}>
                            <Pie
                                data={accountStatusArray}
                                dataKey="count"
                                nameKey="percentage"
                                cx="50%"
                                cy="50%"
                                innerRadius={50}
                                outerRadius={80}
                            >
                                {accountStatusArray.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={colors[index]} />
                                ))}
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                    <div className={styles.insidegraphbox}>
                        <div className={styles.graphdata}>
                            {accountStatusArray.map((item, i) => (<p key={i}>
                                <span style={{ backgroundColor: colors[i] }}></span>{" "}
                                {item.name} - {item.count}
                            </p>))}
                        </div>
                    </div>
                </div>
                <div className={styles.graphbox}>
                    <h2>QR type</h2>
                    <ResponsiveContainer width="100%" height={220} className={styles.responsivecontainer}>
                        <PieChart width={250} height={250}>
                            <Pie
                                data={qrTypeArray}
                                dataKey="count"
                                nameKey="percentage"
                                cx="50%"
                                cy="50%"
                                innerRadius={50}
                                outerRadius={80}
                            >
                                {qrTypeArray.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={colors[index]} />
                                ))}
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                    <div className={styles.insidegraphbox}>
                        <div className={styles.graphdata}>
                            {qrTypeArray.map((item, i) => (<p key={i}>
                                <span style={{ backgroundColor: colors[i] }}></span>{" "}
                                {item.name} - {item.count}
                            </p>))}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles['top-users-categories']}>
                <div className={styles['top-categories']}>
                    <h5>Top categories ({topCategories.length})</h5>
                    <div>
                        <span>Category</span>
                        <span>QR count</span>
                    </div>
                    {topCategories.map((item, i) => (
                        <div key={i}>
                            <span>{item.categoryName}</span>
                            <span>{item.qrCodeCount}</span>
                        </div>
                    ))}
                </div>
                <div className={styles['top-users']}>
                    <h5>Top users ({topUsers.length})</h5>
                    <div>
                        <span>Name</span>
                        <span>Email</span>
                        <span>QR count</span>
                        <span>Total scans</span>
                    </div>
                    {topUsers.map((item, i) => (
                        <div key={i}>
                            <span>{item.firstName + " " + item.lastName}</span>
                            <span>{item.email}</span>
                            <span>{item.qrCodeCount}</span>
                            <span>{item.totalScans}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </>);
}
