import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CreateManageQR from '../Components/CreateManageQR/CreateManageQR';
import { faqs } from '../Components/FAQs';
import styles from './home.module.css';

export default function Home(){
    const [ activeFaq, setActiveFaq ] = useState(1);

    return (<>
        <Helmet>
            <title>QR Code Generator | Create Free QR code with logo</title>
            <meta name="description" content="Create custom QR codes effortlessly with our user-friendly QR Code Generator." />
        </Helmet>
        <section id={styles['banner']}>
            <div className="container">
                <div className={styles["banner"]}>
                    <div>
                        <h1>With great data comes great responsibility</h1>
                        <p>Create, track and manage multiple QR Code and campaigns with the industry’s most comprehensive and user-friendly QR Code tracking system.</p>
                        <Link to="/login"><button className="btn btn-light">GENERATE QR CODE</button></Link>
                    </div>
                    <div>
                        <img src="../assets/images/banner.png" alt="banner" width="100%" />
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className='container'>
                <div className={styles['about']}>
                    <div>
                        <img src="../assets/images/home-about.png" alt="about" width="100%" />
                    </div>
                    <div>
                        <h6>Welcome to scanonn</h6>
                        <h2>your go-to source for QR Code generator and tracking solutions</h2>
                        <p>Welcome to Scanonn, where we harness the power of Generative AI to revolutionize QR code generator and tracking solutions. Our mission is to empower businesses and individuals with an intuitive and reliable platform that enhances data tracking, boosts efficiency, and streamlines processes. </p>
                        <p>With our cutting-edge QR code generator, you can create custom codes in seconds and monitor valuable data in real-time. Our dedicated team of professionals is committed to delivering exceptional service and support, ensuring that you have the tools and expertise needed to achieve your goals.</p>
                        <p>Whether you're a small business owner, a marketing professional, or a healthcare provider, Scanonn is here to provide you with the innovative solutions you need to succeed in today's dynamic environment.</p>
                        <Link to="/product"><button className="btn btn-primary">Explore more</button></Link>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className='container'>
                <div className={styles['why-scanonn']}>
                    <div>
                        <h2>Why ScanOnn?</h2>
                        <p>Scanonn sets the benchmark as the leading QR code generator, leveraging Generative AI to provide a vital tool for businesses of all scales. Craft personalized QR codes embellished with your brand's logo, colors, and design elements. Embark on this journey with our complimentary trial.</p>
                        <div className={styles['why-scanonn-boxes']}>
                            <div>
                                <img src="../assets/images/generate-qr.png" alt="generate-qr" width="100%" />
                                <p>Enhanced QR Performance</p>
                                <span>Optimize QR code performance with our AI solution.</span>
                            </div>
                            <div>
                                <img src="../assets/images/campaign-folders.png" alt="campaign-folders" width="100%" />
                                <p>Unlimited QR Creation</p>
                                <span>Create unlimited QR codes quickly and easily.</span>
                            </div>
                            <div>
                                <img src="../assets/images/bulk-creation.png" alt="bulk-creation" width="100%" />
                                <p>Multi-User Support</p>
                                <span>Collaborate with ease using multiple user accounts.</span>
                            </div>
                            <div>
                                <img src="../assets/images/scan-statistics.png" alt="scan-statistics" width="100%" />
                                <p>Unlimited Scans</p>
                                <span>Reach more people with unlimited QR code scans.</span>
                            </div>
                            <div>
                                <img src="../assets/images/design-options.png" alt="design-options" width="100%" />
                                <p>Comprehensive Analytics</p>
                                <span>Gain insights with AI-driven monitoring and analytics.</span>
                            </div>
                            <div>
                                <img src="../assets/images/dynamic-qr-codes.png" alt="dynamic-qr-codes" width="100%" />
                                <p>White Labeling</p>
                                <span>Personalize QR codes with your brand using CNAME.</span>
                            </div>
                            <div>
                                <img src="../assets/images/dynamic-qr-codes.png" alt="dynamic-qr-codes" width="100%" />
                                <p>Industry Templates</p>
                                <span>Choose from templates tailored to your industry.</span>
                            </div>
                            <div>
                                <img src="../assets/images/dynamic-qr-codes.png" alt="dynamic-qr-codes" width="100%" />
                                <p>Customization Support</p>
                                <span>Integrate and customize with expert support.</span>
                            </div>
                            <div>
                                <img src="../assets/images/dynamic-qr-codes.png" alt="dynamic-qr-codes" width="100%" />
                                <p>No-Code Platform</p>
                                <span>Expand your business effortlessly with our platform.</span>
                            </div>
                        </div>
                        <Link to="/product"><button className="btn btn-primary">Explore more</button></Link>
                    </div>
                    <div>
                        <img src="../assets/images/why-scanonn.png" alt="why-scanonn" width="100%" />
                    </div>
                </div>
            </div>
        </section>
        <section id={styles['free-qr-generator']}>
            <div className="container">
                <h2>Create Your Own Free QR Code in Minutes</h2>
                <div className={styles['free-qr-generator']}>
                    <div>
                        <img src="../assets/images/set-qr-content.png" alt="set-qr-content" width="100%" />
                        <p>Generate Your QR Code Quickly</p>
                        <span>Create your custom QR code swiftly and easily with Plagrams Free.</span>
                    </div>
                    <div>
                        <img src="../assets/images/customize-design.png" alt="customize-design" width="100%" />
                        <p>Choose Your QR Code Content</p>
                        <span>Select from a diverse range of content options including PDFs, menus, videos, business cards, websites, apps, and more.</span>
                    </div>
                    <div>
                        <img src="../assets/images/generate-qrcode.png" alt="generate-qrcode" width="100%" />
                        <p>Customize and Design Your QR Code</p>
                        <span>Tailor your QR code to your liking by entering your desired information and utilizing our QR code generator to create a unique design.</span>
                    </div>
                    <div>
                        <img src="../assets/images/get-report.png" alt="report" width="100%" />
                        <p>Download Your QR Code</p>
                        <span>Obtain your QR code in various formats such as PDF, PNG, and SVG. Print it or display it digitally, and you're all set!</span>
                    </div>
                </div>
            </div>
        </section>
        <section id={styles['create-manage']}>
            <CreateManageQR />
        </section>
        <section id={styles['faqs']}>
            <div className='container'>
                <h2>Frequently Asked Questions</h2>
                <div className={styles['faqs']}>
                    <div>
                        {faqs.map((item, i) => (
                            <div className={styles['faq-blocks']} key={i}>
                                <div onClick={() => setActiveFaq(item.id)}>
                                    <i className={`fa fa-${activeFaq === item.id?"minus":"plus"}-circle float-right`}></i>
                                    <p className={styles['faq-question']}>0{item.id} <span> {item.question}</span></p>
                                </div>
                                <div className={`collapse ${activeFaq === item.id?"show":"hide"}`}>
                                    <div className={styles['faq-answers']}>
                                        {item.answer}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div>
                        <img src="../assets/images/faq.png" alt="faq" width="100%" />
                    </div>
                </div>
            </div>
        </section>
    </>)
}
